<template>
  <div class="avatar" :style="`{ width: ${width}px, height: ${height}px }`" :class="floatLeftAdded()" v-if="user.avatar_path">
    <span class="user-online" :class="onlineStyles()" :style="{ width: onlineWidth + 'px', height: onlineHeight + 'px' }" v-if="online"></span>
    <img 
      :src="user.avatar_path"
      :style="`border-radius: ${borderRadius}px`"
      loading="lazy" 
      :width="width" 
      :height="height"
      :alt="alt"
      @load="handleImageLoad"
      @error="handleImageError">
    <div v-if="!imageLoaded" class="avatar-placeholder" :style="{ width: width + 'px', height: height + 'px' }">
      <font-awesome-icon icon="user" size="lg" />
    </div>
  </div>
  <div class="empty-user-avatar avatar" :class="floatLeftAdded()" 
       :style="{ 
         backgroundColor: colors[getColorIndex()],
         width: width + 'px', 
         height: height + 'px',
         fontSize: (width / 2.5) + 'px'
       }" 
       v-else>
    <span class="user-online" :class="onlineStyles()" :style="{ width: onlineWidth + 'px', height: onlineHeight + 'px' }" v-if="online"></span>
    {{ getInitials(user) }}
  </div>
</template>

<script>

export default {
  name: 'Avatar',
  props: {
    user: Object,
    alt: { type: String, default: '' },
    floatLeft: { type: Boolean, default: false },
    onlinePosition: { type: String, default: 'bottom', validator: (value) => ['top', 'bottom'].includes(value) },
    online: { type: Boolean, default: false },
    width: { type: Number, default: 50 },
    height: { type: Number, default: 50 },
    onlineWidth: { type: Number, default: 10 },
    onlineHeight: { type: Number, default: 10 },
    borderRadius: { type: Number, default: 100 },
    onlineBackgroundColor: { type: String, default: 'green', validator: (value) => ['red', 'green'].includes(value) }
  },
  data() {
    return {
      colors: ['#a2b9bc', '#6b5b95', '#feb236', '#d64161', '#ff7b25', '#b2ad7f', '#878f99'],
      imageLoaded: false
    }
  },
  methods: {
    getInitials() {
      let initials = '';
      if (this.user.first_name || this.user.surname) {
        initials = (this.user.first_name?.[0] || '') + (this.user.surname?.[0] || '');
      } else if (this.user.nickname) {
        initials = this.user.nickname[0];
      } else {
        initials = 'SP';
      }
      return initials.toUpperCase();
    },
    getColorIndex() {
      const initials = this.getInitials();
      let sum = 0;
      for (let i = 0; i < initials.length; i++) {
        sum += initials.charCodeAt(i);
      }
      return sum % this.colors.length;
    },
    floatLeftAdded() {
      // hack for avatar on desktop header
      return this.floatLeft ? 'float-left' : '';
    },
    onlineStyles() {
      return `background-${this.onlineBackgroundColor} ${this.onlinePosition}`;
    },
    handleImageLoad() {
      this.imageLoaded = true
    },
    handleImageError(e) {
      // e.target.src = 'path/to/fallback/image.jpg' // Add your fallback image path
    },
  }
}
</script>

<style scoped>
  .avatar {
    color: #fff; 
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:100%;
    font-weight: 600;
    position: relative;
  }

  .background-red {
    background: rgba(212, 19, 13, 1);
  }

  .background-green {
    background: rgba(2, 129, 6, 1);
  }

  .user-online {
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    border: 1px solid #fff
  }

  .user-online.top {
    top: 0px;
    bottom: auto;
    right: -2px;
  }

  .user-online.bottom {
    bottom: 0px;
    right: 4px;
  }

  .avatar-placeholder {
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eeeded;
    position: absolute;
    top: 0px;
  }
</style>
