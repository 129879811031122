<template>
  <div class="container">
    <div class="chat">
      <div class="chat__topic">
        <div class="arrow-left-link-wrap">
          <a :href="sportEventLink()" style="width: 30px; height: 30px; margin-top: 15px; display: block; margin-right: -15px;">
            <font-awesome-icon icon="arrow-left" size="lg" />
          </a>
        </div>

        <div class="users-list-link-wrap" @click.stop="changeBodyType()">
          <font-awesome-icon icon="user-alt" size="1x" v-if="this.bodyType == 'messages'" />
          <font-awesome-icon icon="comments" size="1x" v-if="this.bodyType == 'users'" />
          {{ this.chatTitle() }}
        </div>

        <div class="">
        </div>
      </div>

      <div class="chat__users__block" v-if="bodyType == 'users'">
        <div class="chat__users__wrap">
          <div class="chat__user" v-for="participant in participants">
            <div class="chat__user-avatar">
              <div style="position: relative; width: 40px; margin: auto;">
                <span class="chat__user-online" v-if="isUserOnline(participant)"></span>
                <img :src="imgPath(participant)" loading="lazy">
              </div>
            </div>
            <div class="chat__user-name">
              <a :href="userLink(participant)">@{{ participant.nickname }}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="chat__messages" ref="scrollList" v-if="bodyType == 'messages' && Object.keys(groupedMessages).length > 0">
        <div class="chat__load-previous-messages" v-if="currentMessages.length >= 50 && showLoadPreviousMessagesButton">
          <a href="" v-on:click.prevent="loadPreviousMessages()">
            {{ I18n.t("chat.previous_messages_link") }}
          </a>
        </div>
        <div v-for="(dateMessages, date) in groupedMessages">
          <div class="chat__messages_date">{{ date }}</div>
          <chat-message v-for="message, i in dateMessages" :message="message" :full_message="showFullMessage(dateMessages, i)" :author="messageAuthor(message)" :isAuthorCreator="messageAuthor(message).id == creator_id"  v-if="!!messageAuthor(message)" />
        </div>
      </div>

      <div class="empty-messages" v-else-if="bodyType == 'messages' && Object.keys(groupedMessages).length == 0">
        <div>
          <img class="avatar" src="../../images/message2.svg" height="100" width="100" loading="lazy">
        </div>

        {{ I18n.t("chat.no_messages") }}
      </div>

      <div class="typing-wrap" v-if="this.typing.length > 0">
        <div v-for="nickname in typing" class="typing-item">{{ nickname }}</div>
        <div>{{ I18n.t("chat.typing") }}...</div>
      </div>

      <div class="chat__input-wrapper" style="position: relative;">
        <textarea class="chat__input" id="messages-input" ref="roomTextarea" v-model="currentMessage" @input="onChangeInput" @keydown.esc="escapeTextarea" v-if="$mq === 'sm'" >
        </textarea>

        <textarea class="chat__input" id="messages-input" ref="roomTextarea" @keydown="sendMessageDesktop" @input="onChangeInput" v-model="currentMessage" :placeholder="I18n.t('chat.placeholder')" @keydown.esc="escapeTextarea" v-if="$mq !== 'sm'" >
        </textarea>

        <div style="display: flex; align-items: center; position: relative;">
          <emoji-picker @emoji="insert">
            <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
              </svg>
            </div>
            <div class="emoji-picker" slot="emoji-picker" slot-scope="{ emojis, insert, display }">
              <div>
                <div>
                  <div v-for="(emojiGroup, category) in emojis" :key="category">
                    <h5>{{ category }}</h5>
                    <div>
                      <span
                        v-for="(emoji, emojiName) in emojiGroup"
                        :key="emojiName"
                        @click="insert(emoji)"
                        :title="emojiName"
                      >{{ emoji }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </emoji-picker>

          <div @click.stop.prevent="sendMessageMobile" v-if="$mq === 'sm'" >
            <div v-if="this.currentMessageExists" id="icon-send" class="send-icon-svg">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="40" height="40" viewBox="0 0 35 35" ><path id="vac-icon-send" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path><!----></svg>
            </div>

            <div v-else id="icon-disabled" class="send-icon-svg">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="40" height="40" viewBox="0 0 35 35"><path id="vac-icon-send-disabled" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path><!----></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import _ from "lodash"
  import showAvatar from "../../utils/avatar"

  export default {
    props: {
      sport_event_id: Number,
      creator_id: Number,
      current_user: Object,
      participants: Array,
      messages: Array
    },
    data() {
      return {
        currentMessage: '',
        currentMessages: this.messages,
        participantsOnlineIds: [],
        typing: [],
        typingCounter: 0,
        showLoadPreviousMessagesButton: true,
        sendingMessage: false,
        keepKeyboardOpen: false,
        search: '',
        bodyType: "messages",
      }
    },
    channels: {
      SportEventChatChannel: {
        connected() {
          console.log('I am connected.')
        },
        rejected() {},
        received(data) {
          if (data.messages && data.messages.author_id != this.current_user.id) {
            this.currentMessages.push(data.messages)

            if (document.hidden) {
              const favicon = document.querySelectorAll('[rel="icon"]')[0]
              favicon.setAttribute("href", require("images/favicon_pinned.ico"))
            }
          }

          if (data.typing) {
            this.typing = data.typing.filter(x => !data.typing.includes(this.current_user.nickname))
          }

          if (data.user_ids) {
            this.participantsOnlineIds = data.user_ids

            if (data.user_ids.length > 1)
              this.currentMessages.filter(m => !m.viewed).forEach(m => m.viewed = true)
          }
        },
        disconnected() {
          console.log('I am disconnected.')
        }
      }
    },
    mounted() {
      this.$cable.subscribe({ channel: "SportEventChatChannel", sport_event_id: this.sport_event_id })
      this._scrollDown()

      document.getElementById("messages-input").addEventListener("focus", () => {
        document.getElementById("messages-input").style.opacity = 0;
        setTimeout(() => document.getElementById("messages-input").style.opacity = 1);
      });

      const thisLocal = this

      document.addEventListener("visibilitychange", function() {
        const favicon = document.querySelectorAll('[rel="icon"]')[0]
        favicon.setAttribute("href", require("images/favicon.ico"))

        if (document.hidden) {
          console.log('document hidden')

          thisLocal.$cable.perform({
            channel: "SportEventChatChannel",
            action: "tab_hidden",
            data: {
              sport_event_id: thisLocal.sport_event_id
            }
          })
        } else {
          console.log('document active')

          thisLocal.$cable.perform({
            channel: "SportEventChatChannel",
            action: "tab_active",
            data: {
              sport_event_id: thisLocal.sport_event_id
            }
          })
        }
      });

      document.getElementById("messages-input").addEventListener(
        "DOMCharacterDataModified",
        function (event) {
          if (thisLocal.participantsOnlineIds.length > 1) {
            thisLocal.$cable.perform({
              channel: "SportEventChatChannel",
              action: "typing",
              data: {
                nickname: thisLocal.current_user.nickname,
                sport_event_id: thisLocal.sport_event_id
              }
            })

            // POTENTIAL MEMORY LEAKS, CAUSE A LOT CLOJURES
            const thisLocalEvent = thisLocal

            thisLocal.typingCounter = thisLocal.typingCounter + 1

            const counter = thisLocal.typingCounter

            setTimeout(function() {
              thisLocalEvent.stopTyping(counter)
            }, 1500)
          }
        },
        false
      )

      $("#messages-input").click(function() {
        const el = document.getElementById("messages-input")
        el.focus();
        el.scrollIntoView();
      //   const el = document.getElementById("messages-input")
      //   el.focus();
      //   if (typeof window.getSelection != "undefined"
      //           && typeof document.createRange != "undefined") {
      //     var range = document.createRange();
      //     range.selectNodeContents(el);
      //     range.collapse(false);
      //     var sel = window.getSelection();
      //     sel.removeAllRanges();
      //     sel.addRange(range);
      //   } else if (typeof document.body.createTextRange != "undefined") {
      //     var textRange = document.body.createTextRange();
      //     textRange.moveToElementText(el);
      //     textRange.collapse(false);
      //     textRange.select();
      //   }
      })

      $("#messages-input").click()
    },
    updated() {
      this.$nextTick(this._scrollDown())
    },
    computed: {
      groupedMessages() {
        return _.groupBy(this.currentMessages, "created_at_date")
      },
      groupedParticipants() {
        return _.groupBy(this.participants, "id")
      },
      sendButtonClassName() {
        if (this.currentMessage) {
          return "chat__send-button-opacity"
        }

        return ""
      },
      currentMessageExists() {
        return this.currentMessage.length > 0
      }
    },
    methods: {
      handleMessageInput(e) {
        this.currentMessage = e.target.innerHTML
      },
      _scrollDown () {
        if (!this.$refs.scrollList) {
          return
        }

        this.$refs.scrollList.scrollTop = this.$refs.scrollList.scrollHeight
      },
      stopTyping(counter) {
        if (counter < this.typingCounter)
          return

        this.$cable.perform({
          channel: "SportEventChatChannel",
          action: "stop_typing",
          data: {
            nickname: this.current_user.nickname,
            sport_event_id: this.sport_event_id
          }
        })
      },
      getTextareaRef() {
        return this.$refs.roomTextarea
      },
      onChangeInput() {
        if (this.getTextareaRef()?.value || this.getTextareaRef()?.value === '') {
          this.message = this.getTextareaRef()?.value
        }
        this.keepKeyboardOpen = true
        this.resizeTextarea()
      },
      resizeTextarea() {
        const el = this.getTextareaRef()

        if (!el) return

        const padding = window
          .getComputedStyle(el, null)
          .getPropertyValue('padding-top')
          .replace('px', '')

        el.style.height = 0
        el.style.height = el.scrollHeight - padding * 2 + 'px'
      },
      resetTextareaSize() {
        if (this.getTextareaRef()) {
          this.getTextareaRef().style.height = '20px'
        }
      },
      escapeTextarea() {
        this.resetTextareaSize()
        this.currentMessage = ''
      },
      getCharPosition(tagChar) {
        const cursorPosition = this.getTextareaRef().selectionStart

        let position = cursorPosition
        // while (position > 0 && this.currentMessage.charAt(position - 1) !== tagChar) {
        //   position--
        // }

        const endPosition = this.getTextareaRef().selectionEnd

        return { position, endPosition }
      },
      sendMessageDesktop(event) {
        if (event.keyCode === 13 && !event.shiftKey) {
          event.preventDefault();
          event.stopPropagation();

          if (this.sendingMessage)
            return

          this.sendingMessage = true

          const message = this.currentMessage
          const date = new Date()

          const generatedId = -(parseInt(Math.random() * 10000000))
          const currentMinutes = ("0" + date.getMinutes()).slice(-2)

          this.currentMessages.push({
            body: this.currentMessage,
            id: generatedId,
            author_id: this.current_user.id,
            author_nickname: this.current_user.nickname,
            created_at_date: `${("0" + date.getDate()).slice(-2)}.${("0" + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`,
            created_at_time: `${date.getHours()}:${currentMinutes}`,
            viewed: this.participantsOnlineIds.length > 1
          })
          this.currentMessage = ""

          this.resetTextareaSize()
          this.getTextareaRef().focus()

          axios
            .post(
              "/api/v1/chat_messages",
              { chat_message: { body: message, sport_event_id: this.sport_event_id } }
            )
            .then(response => {
              this.sendingMessage = false
            })
            .catch(error => {
              this.errors = true
              this.sendingMessage = false
            })
        }
      },
      sendMessageMobile(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.sendingMessage)
          return

        if (this.currentMessage.length == 0) {
          return
        }

        const message = this.currentMessage;

        this.sendingMessage = true

        $("#messages-input").trigger("click")

        const date = new Date()

        const generatedId = -(parseInt(Math.random() * 10000000))
        const currentMinutes = ("0" + date.getMinutes()).slice(-2)

        this.currentMessages.push({
          body: this.currentMessage,
          id: generatedId,
          author_id: this.current_user.id,
          author_nickname: this.current_user.nickname,
          created_at_date: `${("0" + date.getDate()).slice(-2)}.${("0" + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`,
          created_at_time: `${date.getHours()}:${currentMinutes}`,
          viewed: this.participantsOnlineIds.length > 1
        })
        this.currentMessage = ""
        this.resetTextareaSize()
        this.getTextareaRef().focus()

        axios
          .post(
            "/api/v1/chat_messages",
            { chat_message: { body: message, sport_event_id: this.sport_event_id } }
          )
          .then(response => {
            this.sendingMessage = false
          })
          .catch(error => {
            this.errors = true
            this.sendingMessage = false
          })
      },
      loadPreviousMessages() {
        const latestMessage = this.currentMessages[0]

        axios
          .get(
            "/api/v1/chat_messages",
            { params: { last_message_id: latestMessage.id, sport_event_id: this.sport_event_id } }
          )
          .then(response => {
            const { messages } = response.data

            if (messages.length == 0) {
              this.showLoadPreviousMessagesButton = false
              return
            }

            this.currentMessages = [...messages, ...this.currentMessages]

            // messages.forEach(m => this.currentMessages.unshift(m))
          })
          .catch(error => {
            this.errors = true
          })
      },
      showFullMessage(messages, currentMessageIndex) {
        if (currentMessageIndex == 0) {
          return true
        }

        const currentMessage = messages[currentMessageIndex]
        const prevMessage = messages[currentMessageIndex - 1]

        if (currentMessage.author_id != prevMessage.author_id) {
          return true
        }

        if (currentMessage.created_at_time != prevMessage.created_at_time) {
          return true
        }

        return false
      },
      isUserOnline(participant) {
        return _.includes(this.participantsOnlineIds, participant.id)
      },
      insert(emoji) {
        // tried to insert in cursor place
        // const { position, endPosition } = this.getCharPosition(':')

        // console.log('position', position);
        // console.log('end position', endPosition)

        // console.log('part 1', this.currentMessage.slice(0, position - 1))
        // console.log('part 2', this.currentMessage.slice(position, this.currentMessage.length - 1))
        // const finalPosition = position > 0 ? position - 1 : 1

        // this.currentMessage =
        //   this.currentMessage.substr(0, finalPosition) +
        //   emoji +
        //   this.currentMessage.substr(endPosition, this.currentMessage.length - 1)

        this.currentMessage += emoji;

        this.getTextareaRef().focus()
      },
      sportEventLink() {
        return `/sport_events/${this.sport_event_id}`
      },
      changeBodyType() {
        if (this.bodyType == "messages") {
          this.bodyType = "users"
        } else {
          this.bodyType = "messages"
        }
      },
      chatTitle() {
        if (this.bodyType == "messages") {
          return I18n.t("chat.members")
        } else {
          return I18n.t("chat.messages")
        }
      },
      imgPath(user) {
        return showAvatar(user, 50)
      },
      userLink(user) {
        return `/users/${user.id}`
      },
      messageAuthor(message) {
        let author = this.groupedParticipants[message.author_id]
        author = author ? author[0] : author
        return author
      }
    }
  }
</script>

<style scoped>

  .container {
    max-width: 760px;
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    background: #fff;
  }

  .chat {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .chat__topic {
    height: 61px !important;
    max-height: 54px;
    overflow: hidden;
    position: relative;
    word-break: break-word;
    word-wrap: break-word;
    padding-bottom: 1px;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.231;
    color: #004d99!important;
    display: flex;
    justify-content: space-between;
    flex-basis: 10%;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);
    align-items: center;
  }

  .chat__topic a {
    text-decoration: none;
  }

  .chat__topic svg {
  }

  .arrow-left-link-wrap {
    margin-left: 15px;
  }

  .users-list-link-wrap {
    align-self: center;
    padding: 10px;
    user-select: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    font-size: 15px;
    color: black;

    @media (max-width: 640px) {
      font-size: 14px;
    }
  }

  .users-list-link-wrap:hover {
    box-shadow: 0px 2px 5px #b9b6b6;

    @media (min-width: 320px) and (max-width: 767px) {
      box-shadow: none;
    }
  }

  .empty-messages {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    font-size: 16px;

    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 16px;
    }
  }

  .chat__messages {
    padding-top: 10px;
    padding-bottom: 22px;
    overflow-y: auto;
    height: 100%;
    background-size: 100%;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 320px) and (max-width: 767px) {
      padding-left: 15px;
      padding-right: 10px;
    }
  }

  @-moz-document url-prefix() {
    .chat__messages {
      padding-bottom: 0px;
      margin-bottom: 22px;
    }
  }

  .chat__input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 320px) and (max-width: 767px) {
      padding-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .chat__input {
    /* Remove First */
    /*-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    outline: 0;
    min-height: 36px;
    width: 100%;
    resize: none;
    max-height: 200px;
    border-radius: 6px;
    line-height: 17px;
    padding: 9px 40px 10px 13px;
    margin-bottom: 0;
    word-wrap: break-word;
    box-sizing: border-box;
    border: solid 1px #d3d9de;
    font-size: 14px;

    @media (min-width: 320px) and (max-width: 767px) {
      width: 90%;
      font-size: 16px;
      border-radius: 20px;
    }*/

    min-height: 20px;
    padding-left: 12px;
    height: 20px;

    max-height: 300px;
    overflow-y: auto;
    height: 20px;
    width: 100%;
    line-height: 20px;
    outline: 0;
    resize: none;
    border-radius: 10px;
    padding: 7px 16px;
    box-sizing: content-box;
    font-size: 14px;
    max-width: 640px;
    border: 1px solid #e1e4e8;

    @media (min-width: 320px) and (max-width: 767px) {
      border-radius: 20px;
      padding: 6px 16px;
      width: 70%;
      font-size: 100%;
    }
  }

  .chat__messages_date {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0px 24px;
    padding-top: 12px;
    padding-bottom: 10px;
    font-size: 11px;
    line-height: 1.273;
    color: #333;
    text-align: center;
    box-sizing: border-box;
  }

  .chat__load-previous-messages {
    text-align: center;
    padding-bottom: 10px;
  }

  .chat__load-previous-messages a {
    font-size: 14px;
    text-decoration: none;
    color: #565656;
  }

  .chat__users__block {
    height: 100%;
    padding-left: 15px;
    padding-top: 10px;
    overflow-y: auto;
    flex-basis: 87%;
  }

  .chat__users__wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .chat__user {
    margin-right: 15px;
    padding-bottom: 20px;
  }

  .chat__user-online {
    background: #0fd815;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    bottom: -2px;
    right: 4px;
    position: absolute;
  }

  .chat__user-name {
    text-align: center;
    font-size: 14px;
    margin-top: 6px;
  }

  .chat__user-name a {
    text-decoration: none;
  }

  .chat__user-avatar {
    position: relative;
    text-align: center;
  }

  .chat__user-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  label.chat__send-label {
    width: 36px;
    min-width: 0;
    height: 32px;
    padding: 0;
    border: 0;
    margin-bottom: 0px;
/*    background-image: url(../../images/Iconset.png);*/
    background-repeat: no-repeat;
    background-position: 2px -860px;
    background-size: 42px 1171px;
  }

  #vac-icon-send {
    fill: #1976d2;
  }

  #vac-icon-send-disabled {
    fill: #9ca6af;
  }

  .chat__send-button-opacity {
    opacity: 1 !important;
  }

  .send-icon-svg {
    margin-left: 12px;
    margin-right: -14px;
    margin-bottom: -10px;
  }

  .typing-wrap {
    display: flex;
    font-size: 12px;
    color: grey;
    padding-left: 20px;
    margin-bottom: 10px;

    @media only screen and (max-width: 600px) {
      padding-left: 15px;
    }
  }

  .typing-item {
    margin-right: 5px;
  }

  .emoji-invoker {
/*    position: absolute;
    top: 0.4rem;
    right: 3%;*/
    margin-left: 8px;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;

    @media (min-width: 320px) and (max-width: 767px) {
      right: 12%;
    }
  }

  .emoji-invoker:hover {
    transform: scale(1.1);
  }

  .emoji-invoker > svg {
    fill: #000;
  }

  .emoji-picker {
    position: absolute;
    bottom: 50px;
    right: 1px;
    z-index: 10;
    font-family: Montserrat;
    border: 1px solid #ccc;
    width: 16rem;
    height: 20rem;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 1px 1px 8px #c7dbe6;

    @media only screen and (max-width: 600px) {
      width: 18rem;
    }
  }

  .emoji-picker h5+div {
    display: flex;
    flex-wrap: wrap;
  }

  .emoji-picker__search {
    display: flex;
  }

  .emoji-picker__search > input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
  }

  .emoji-picker h5 {
    margin: 8px 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
    font-family: "Graphik", "Helvetica Neue", helvetica, "Apple Color Emoji", arial, sans-serif;
    font-size: 15px;
  }

  .emoji-picker span {
    font-size: 22px;
    margin-right: 8px;
    display: block;
    margin-bottom: 6px;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      font-size: 32px;
    }
  }

  .emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .emoji-picker .emojis:after {
    content: "";
    flex: auto;
  }

  .emoji-picker .emojis span {
    padding: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
  }

  .emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
  }

  .mobile-logo {
    background: url("../../images/logo.svg");
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    position: relative;
    top: -12px;
    left: 50%;
  }

</style>
