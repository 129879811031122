<template>
  <div class="user-show">
    <div class="top-content">
      <div class="avatar-background" v-if="$mq == 'sm'">
        <div class="user-avatar">
          <a v-on:click.prevent="openUserAvatarModal()" style="cursor: pointer;">
            <user-avatar :user="user" :width="80" :height="80" :alt="user.nickname" />
          </a>
        </div>
        <div class="user-rank">
          <div :class="logoName(this.user.rank)"></div>
          <div class="new-rank-percentage" v-if="this.isCurrentUser() && this.user.new_rank_progress > 0">
            {{ this.user.new_rank_progress }}%
          </div>
        </div>
      </div>

      <div class="action-buttons"  v-if="$mq == 'sm'">
        <div>
          <div class="settings-button-wrap" v-if="this.isCurrentUser()">
            <a class="btn btn-sm cta-btn edit-button" href="/users/edit_profile">
              <font-awesome-icon icon="cogs" size="sm" />
              {{ I18n.t("sport_events.settings_button_change") }}
            </a>
          </div>

          <div class="invite-button-wrap" v-if="!this.currentUser.is_guest && !this.isCurrentUser()">
            <div style="margin-bottom: 12px; text-align: right;">
              <a class="btn cta-btn purple" :href="`/users/${this.user.id}/invite`">
                {{ I18n.t("users.profile.invite_button") }}
              </a>
            </div>
            <div>
              <a class="btn cta-btn send-message-button" style="font-weight: 450;" :href="`/messages/new?c_uid=${this.user.id}`">
                {{ I18n.t("users.profile.send_message") }}
              </a>
            </div>
          </div>

          <div style="margin-top: 24px; text-align: right;" v-if="this.user.telegram_link || this.user.instagram_link">
            <div v-if="this.user.telegram_link">
              <svg type="color-fill-current" width="20px" height="20px" version="1.1" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1j5mt50-1 bESOCc"><g><path d="M15 9h-2V7a3 3 0 10-6 0v2H5V7a5 5 0 0110 0v2zM13 13v-2h2v2a5 5 0 01-10 0v-2h2v2a3 3 0 106 0z"></path><path d="M11 7H9v6h2V7z"></path></g></svg>
              <a target="_blank" :href="this.user.telegram_link" style="color: #53535f; font-size: 12px; font-weight: 450;">telegram</a>
            </div>
            <div v-if="this.user.instagram_link" style="margin-top: 12px;">
              <svg type="color-fill-current" width="20px" height="20px" version="1.1" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1j5mt50-1 bESOCc"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M10 5.892a4.108 4.108 0 100 8.216 4.108 4.108 0 000-8.216zm0 6.774a2.667 2.667 0 110-5.333 2.667 2.667 0 010 5.333z"></path><path d="M15.23 5.73a.96.96 0 11-1.92 0 .96.96 0 011.92 0z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10 2c-2.173 0-2.445.01-3.299.048-.851.039-1.432.174-1.941.372a3.918 3.918 0 00-1.417.923c-.445.445-.719.89-.923 1.417-.198.509-.333 1.09-.372 1.941C2.01 7.555 2 7.827 2 10s.01 2.445.048 3.298c.039.852.174 1.433.372 1.942.205.526.478.972.923 1.417.445.445.89.718 1.417.923.509.198 1.09.333 1.942.372C7.555 17.99 7.827 18 10 18s2.445-.01 3.298-.048c.852-.039 1.434-.174 1.942-.372a3.918 3.918 0 001.417-.923 3.92 3.92 0 00.923-1.417c.198-.509.333-1.09.372-1.942.039-.853.048-1.125.048-3.298s-.01-2.445-.048-3.299c-.039-.851-.174-1.433-.372-1.941a3.918 3.918 0 00-.923-1.417 3.92 3.92 0 00-1.417-.923c-.51-.198-1.09-.333-1.942-.372C12.445 2.01 12.172 2 10 2zm0 1.441c2.136 0 2.389.009 3.232.047.78.036 1.204.166 1.486.275.373.146.64.319.92.599.28.28.453.546.598.92.11.282.24.705.275 1.485.039.844.047 1.097.047 3.233s-.008 2.389-.047 3.232c-.035.78-.165 1.204-.275 1.486-.145.373-.319.64-.598.92-.28.28-.547.454-.92.598-.282.11-.706.24-1.486.276-.843.038-1.096.046-3.232.046s-2.39-.008-3.233-.046c-.78-.036-1.203-.166-1.485-.276a2.481 2.481 0 01-.92-.598 2.474 2.474 0 01-.599-.92c-.11-.282-.24-.706-.275-1.486-.038-.843-.047-1.096-.047-3.232s.009-2.39.047-3.233c.036-.78.166-1.203.275-1.485.145-.374.319-.64.599-.92.28-.28.546-.454.92-.599.282-.11.705-.24 1.485-.275.844-.038 1.097-.047 3.233-.047z"></path></g></svg>
              <a target="_blank" :href="this.user.instagram_link" style="color: #53535f; font-size: 12px; font-weight: 450;">instagram</a>
            </div>
          </div>
        </div>
      </div>

      <div class="avatar-rank-large-wrap" v-if="$mq != 'sm'">
        <div class="user-avatar-lg">
          <a v-on:click.prevent="openUserAvatarModal()" style="cursor: pointer;">
            <user-avatar :user="user" :width="80" :height="80" :alt="user.nickname" />
          </a>
        </div>
        <div class="user-rank-lg">
          <div :class="logoName(this.user.rank)"></div>
          <div class="new-rank-percentage" v-if="this.isCurrentUser() && this.user.new_rank_progress > 0">
            {{ this.user.new_rank_progress }}%
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-content">
      <div class="user-main-info-wrap">
        <div class="user-main-info">
          <div class="user-main-info-left">
            <h2 class="user-info__name" style="margin-bottom: 6px;" v-if="!!this.fullName">
              {{ this.fullName ? this.fullName : "" }}
            </h2>
            <div class="nickname" :class="nicknameBig()">
              @{{ this.user.nickname }}
            </div>
            <div class="user-info__birthday" v-if="this.user.age">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.25 4.875C8.25 4.77554 8.28951 4.68016 8.35983 4.60984C8.43016 4.53951 8.52554 4.5 8.625 4.5H9.375C9.47446 4.5 9.56984 4.53951 9.64017 4.60984C9.71049 4.68016 9.75 4.77554 9.75 4.875V5.625C9.75 5.72446 9.71049 5.81984 9.64017 5.89016C9.56984 5.96049 9.47446 6 9.375 6H8.625C8.52554 6 8.43016 5.96049 8.35983 5.89016C8.28951 5.81984 8.25 5.72446 8.25 5.625V4.875Z" fill="black"/>
                <path d="M2.625 0C2.72446 0 2.81984 0.0395088 2.89016 0.109835C2.96049 0.180161 3 0.275544 3 0.375V0.75H9V0.375C9 0.275544 9.03951 0.180161 9.10983 0.109835C9.18016 0.0395088 9.27554 0 9.375 0C9.47446 0 9.56984 0.0395088 9.64017 0.109835C9.71049 0.180161 9.75 0.275544 9.75 0.375V0.75H10.5C10.8978 0.75 11.2794 0.908035 11.5607 1.18934C11.842 1.47064 12 1.85218 12 2.25V10.5C12 10.8978 11.842 11.2794 11.5607 11.5607C11.2794 11.842 10.8978 12 10.5 12H1.5C1.10218 12 0.720644 11.842 0.43934 11.5607C0.158035 11.2794 0 10.8978 0 10.5V2.25C0 1.85218 0.158035 1.47064 0.43934 1.18934C0.720644 0.908035 1.10218 0.75 1.5 0.75H2.25V0.375C2.25 0.275544 2.28951 0.180161 2.35984 0.109835C2.43016 0.0395088 2.52554 0 2.625 0V0ZM0.75 3V10.5C0.75 10.6989 0.829018 10.8897 0.96967 11.0303C1.11032 11.171 1.30109 11.25 1.5 11.25H10.5C10.6989 11.25 10.8897 11.171 11.0303 11.0303C11.171 10.8897 11.25 10.6989 11.25 10.5V3H0.75Z" fill="black"/>
              </svg>

              {{ I18n.t("users.profile.age", { count: this.user.age }) }}
            </div>

            <div class="user-description">
              {{ this.user.description }}
            </div>

            <div class="main-info-footer">
              <div class="user-geo">
                <div class="user-info__current-place-wrap" v-if="this.isCurrentUser()">
                  <div v-if="this.currentUser.street_name">
                    <div class="user-info__current-place">{{ `${this.currentUser.current_geo}` }}</div>
                    <div class="user-info__current-place__street-name" v-if="this.currentUser.street_name">
                      <font-awesome-icon icon="map-marker-alt" size="1x" />
                      {{ this.currentUser.street_name }}
                    </div>
                  </div>

                  <div v-else-if="this.currentUser.coordinates_available">
                    <div class="user-info__current-place">
                      <font-awesome-icon icon="map-marker-alt" size="1x" />
                      {{ this.currentUser.current_geo }}
                    </div>
                  </div>

                  <div v-else>
                    <div class="user-info__current-place">
                      <font-awesome-icon icon="map-marker-alt" size="1x" />
                      {{ this.currentUser.current_geo }}

                      <div class="submit-button-wrap" style="margin-top: 6px; color: #679fb3;">
                        <a class="light-link" style="font-size: 13px;" v-on:click.prevent="openNewUserAdressModal()">
                          {{ I18n.t("users.edit_form.buttons.address.set_up") }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="user-info__current-place-wrap" v-else>
                  <div class="user-info__current-place">
                    <font-awesome-icon icon="map-marker-alt" size="1x" />
                    {{ `${this.user.current_geo}` }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="user-main-info-right">
            <div>
              <div class="settings-button-wrap" style="text-align: right;" v-if="$mq != 'sm' && this.isCurrentUser()">
                <a class="btn btn-sm cta-btn edit-button" href="/users/edit_profile">
                  <font-awesome-icon icon="cogs" size="sm" />
                  {{ I18n.t("sport_events.settings_button_change") }}
                </a>
              </div>

              <div class="invite-button-wrap" v-if="$mq != 'sm' && !this.currentUser.is_guest && !this.isCurrentUser()">
                <a class="btn purple cta-btn" style="margin-right: 6px;" :href="`/users/${this.user.id}/invite`">
                  {{ I18n.t("users.profile.invite_button") }}
                </a>

                <a class="btn cta-btn send-message-button" :href="`/messages/new?c_uid=${this.user.id}`">
                  {{ I18n.t("users.profile.send_message") }}
                </a>
              </div>

              <div style="margin-top: 12px; text-align: right;" v-if="$mq != 'sm' && (this.user.telegram_link || this.user.instagram_link)">
                <div v-if="this.user.telegram_link">
                  <svg type="color-fill-current" width="20px" height="20px" version="1.1" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1j5mt50-1 bESOCc"><g><path d="M15 9h-2V7a3 3 0 10-6 0v2H5V7a5 5 0 0110 0v2zM13 13v-2h2v2a5 5 0 01-10 0v-2h2v2a3 3 0 106 0z"></path><path d="M11 7H9v6h2V7z"></path></g></svg>
                  <a target="_blank" :href="this.user.telegram_link" style="color: #53535f; font-size: 12px; font-weight: 450;">telegram</a>
                </div>
                <div v-if="this.user.instagram_link" style="margin-top: 12px;">
                  <svg type="color-fill-current" width="20px" height="20px" version="1.1" viewBox="0 0 20 20" x="0px" y="0px" class="ScSvg-sc-1j5mt50-1 bESOCc"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M10 5.892a4.108 4.108 0 100 8.216 4.108 4.108 0 000-8.216zm0 6.774a2.667 2.667 0 110-5.333 2.667 2.667 0 010 5.333z"></path><path d="M15.23 5.73a.96.96 0 11-1.92 0 .96.96 0 011.92 0z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10 2c-2.173 0-2.445.01-3.299.048-.851.039-1.432.174-1.941.372a3.918 3.918 0 00-1.417.923c-.445.445-.719.89-.923 1.417-.198.509-.333 1.09-.372 1.941C2.01 7.555 2 7.827 2 10s.01 2.445.048 3.298c.039.852.174 1.433.372 1.942.205.526.478.972.923 1.417.445.445.89.718 1.417.923.509.198 1.09.333 1.942.372C7.555 17.99 7.827 18 10 18s2.445-.01 3.298-.048c.852-.039 1.434-.174 1.942-.372a3.918 3.918 0 001.417-.923 3.92 3.92 0 00.923-1.417c.198-.509.333-1.09.372-1.942.039-.853.048-1.125.048-3.298s-.01-2.445-.048-3.299c-.039-.851-.174-1.433-.372-1.941a3.918 3.918 0 00-.923-1.417 3.92 3.92 0 00-1.417-.923c-.51-.198-1.09-.333-1.942-.372C12.445 2.01 12.172 2 10 2zm0 1.441c2.136 0 2.389.009 3.232.047.78.036 1.204.166 1.486.275.373.146.64.319.92.599.28.28.453.546.598.92.11.282.24.705.275 1.485.039.844.047 1.097.047 3.233s-.008 2.389-.047 3.232c-.035.78-.165 1.204-.275 1.486-.145.373-.319.64-.598.92-.28.28-.547.454-.92.598-.282.11-.706.24-1.486.276-.843.038-1.096.046-3.232.046s-2.39-.008-3.233-.046c-.78-.036-1.203-.166-1.485-.276a2.481 2.481 0 01-.92-.598 2.474 2.474 0 01-.599-.92c-.11-.282-.24-.706-.275-1.486-.038-.843-.047-1.096-.047-3.232s.009-2.39.047-3.233c.036-.78.166-1.203.275-1.485.145-.374.319-.64.599-.92.28-.28.546-.454.92-.599.282-.11.705-.24 1.485-.275.844-.038 1.097-.047 3.233-.047z"></path></g></svg>
                  <a target="_blank" :href="this.user.instagram_link" style="color: #53535f; font-size: 12px; font-weight: 450;">instagram</a>
                </div>
              </div>
            </div>

            <div class="user-play-status-wrap" style="text-align: right;">
              <div class="user-play-status" style="cursor: pointer;" v-on:click="openPlayStatusModal()">
                <font-awesome-icon icon="circle" size="xs" :class="`play_status_${this.user.play_status}`" />
                {{ I18n.t(`users.play_status.${this.user.play_status}`) }}
              </div>
            </div>
          </div>
        </div>

        <div style="margin-left: 6px; margin-top: 12px;">
          <div >
            <div class="badge badge-yellow-light" v-if="user.finished_competitions_count > 1">
              {{ I18n.t('users.badges.like_to_compete') }}
            </div>

            <div class="badge badge-yellow-light" v-if="user.play_activity_weeks_streak > 1">
              {{ I18n.t('users.badges.constantly_training') }}
            </div>
          </div>
        </div>
      </div>

      <div class="email-confirmation-alert" v-if="this.isCurrentUser()">
        <email-confirmation-alert :user="this.current_user"/>
      </div>

      <div class="ready-status-confirmation" v-if="this.isCurrentUser() && this.currentUser.should_confirm_ready_status">
        <div class="ready-status-confirmation__title">
          {{ I18n.t('users.titles.ready_status_confirmation_title') }}
        </div>
        <div class="ready-status-confirmation__description">
          {{ I18n.t('users.titles.ready_status_confirmation_title_description') }}
        </div>
        <div style="width: 100%;">
          <button class="btn cta-btn purple" style="margin-right: 10px; font-size: 15px;" v-on:click.prevent="updatePlayStatus('ready')">
            {{ I18n.t("users.confirmation_status.buttons.submit") }}
          </button>

          <button class="btn cta-btn grey" style="font-size: 15px;" v-on:click.prevent="updatePlayStatus('not_ready')">
            {{ I18n.t("users.confirmation_status.buttons.skip") }}
          </button>
        </div>

        <div class="ready-status-confirmation-close-wrap">
          <button type="button" class="ready-status-confirmation-close-button close" style="font-size: 20px; text-shadow: none; color: black;" data-dismiss="alert" @click="updatePlayStatus('not_confirmed')">
            &times;
          </button>
        </div>
      </div>

      <div class="user-additional-info">
        <div v-if="this.showStreakNotification && this.isCurrentUser() && this.user.show_streak && this.user.play_activity_weeks_streak > 2 && !this.currentUser.should_confirm_ready_status" style="display: flex; align-items: center; font-size: 14px; line-height: 1.2; padding: 10px; border: 1px solid #602ac1; border-radius: 10px; position: relative; padding-right: 20px; margin: 24px 0px;">
          <div style="margin-right: 6px;">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-lightbulb"><path d="M15 14c.2-1 .7-1.7 1.5-2.5 1-.9 1.5-2.2 1.5-3.5A6 6 0 0 0 6 8c0 1 .2 2.2 1.5 3.5.7.7 1.3 1.5 1.5 2.5"/><path d="M9 18h6"/><path d="M10 22h4"/></svg>
          </div>

          <div v-if="this.user.play_activity_weeks_streak == 3" v-html="`${I18n.t('users.profile.activity_streak.three_weeks', { count: this.user.play_activity_weeks_streak })}`">

          </div>

          <div v-else-if="this.user.play_activity_weeks_streak > 3 && this.user.play_activity_weeks_streak < 6" v-html="`${I18n.t('users.profile.activity_streak.three_five_weeks', { count: this.user.play_activity_weeks_streak })}`">
          </div>

          <div v-else v-html="`${I18n.t('users.profile.activity_streak.super_streak', { count: this.user.play_activity_weeks_streak })}`">
          </div>

          <button type="button" class="close-streak-notification close" style="font-size: 20px; text-shadow: none; color: black;" data-dismiss="alert" @click="closeActivityStreak()">
            &times;
          </button>
        </div>

        <div class="nearest-sport-event" v-if="nearest_event">
          <div class="nearest-sport-event__left-border"></div>
          <div class="nearest-sport-event__header">
            <div class="nearest-sport-event__title">
              <span>{{ I18n.t("users.profile.nearest_event.title") }}</span>
            </div>
            <div class="nearest-sport-event__time-in-words" v-if="['waiting', 'ready'].includes(this.nearest_event.status)">{{ this.nearest_event.start_after_in_words }}</div>
          </div>

          <div class="nearest-sport-event__footer">
            <div class="nearest-sport-event__footer__datetime">
              <div class="nearest-sport-event__footer__date-wrap">
                <div class="nearest-sport-event__footer__date-num">{{ this.nearest_event.start_date.split(".")[0] }}</div>
                <div class="nearest-sport-event__footer__date-month">{{ this.nearest_event.start_month_abbr }}</div>
              </div>

              <div class="nearest-sport-event__footer__wrap">
                <div class="nearest-sport-event__footer__time">{{ this.formattedTime(this.nearest_event)}}</div>
                <div class="nearest-sport-event__footer__sport">{{ I18n.t(`sports.${this.nearest_event.sport_code}`) }}</div>
              </div>
            </div>

            <div class="nearest-sport-event__footer__links-wrap">
              <div class="nearest-sport-event__footer__links-messages">
                <a :href="`/messages/${this.nearest_event.chat_uuid}/`">
                  {{ I18n.t("users.profile.nearest_event.messages") }}
                </a>

                <div class="nearest-sport-event__footer__chat-unread-messages-counter" v-if="this.unread_chat_messages_count > 0 && $mq != 'sm'">
                  {{ this.unread_chat_messages_count }}
                </div>

                <div class="nearest-sport-event__footer__chat-unread-messages" v-if="this.unread_chat_messages_count > 0 && $mq == 'sm'">
                </div>
              </div>

              <div class="nearest-sport-event__footer__links-sport-event">
                <a :href="`/sport_events/${this.nearest_event.id}`" class="btn cta-btn purple btn-sm">
                  {{ I18n.t("users.profile.nearest_event.open_button") }}
                  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6 5.73684L1 10" stroke="white"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="nearest-sport-event" v-else-if="latest_finished_event">
          <div class="latest-sport-event__left-border"></div>
          <div class="nearest-sport-event__header">
            <div class="nearest-sport-event__title">
              <div>
                {{ I18n.t("users.profile.latest_finished_event.title") }}
              </div>

              <div>
                <a :href="`/sport_events/new?sport_event_id=${latest_finished_event.id}`" style="text-transform: none; font-size: 12px; font-weight: 400;">
                  <font-awesome-icon icon="redo" size="sm" />
                  {{ I18n.t('sport_events.retry_link') }}
                </a>
              </div>
            </div>
          </div>

          <div class="nearest-sport-event__footer">
            <div class="nearest-sport-event__footer__datetime">
              <div class="nearest-sport-event__footer__date-wrap">
                <div class="nearest-sport-event__footer__date-num">{{ this.latest_finished_event.start_date.split(".")[0] }}</div>
                <div class="nearest-sport-event__footer__date-month">{{ this.latest_finished_event.start_month_abbr }}</div>
              </div>

              <div class="nearest-sport-event__footer__wrap">
                <div class="nearest-sport-event__footer__time">{{ this.formattedTime(this.latest_finished_event) }}</div>
                <div class="nearest-sport-event__footer__sport">{{ I18n.t(`sports.${this.latest_finished_event.sport_code}`) }}</div>
              </div>
            </div>

            <div class="nearest-sport-event__footer__links-wrap">
              <div class="nearest-sport-event__footer__links-messages">
                <a :href="`/sport_events/${this.latest_finished_event.id}/review`">
                  {{ I18n.t("users.profile.latest_finished_event.reviews") }}
                </a>
              </div>

              <div class="nearest-sport-event__footer__links-sport-event">
                <a :href="`/sport_events/${this.latest_finished_event.id}`" class="btn cta-btn purple btn-sm">
                  {{ I18n.t("users.profile.latest_finished_event.open_button") }}
                  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6 5.73684L1 10" stroke="white"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="user-competition" v-if="this.actual_competition">
          <div class="section-title">
            {{ I18n.t("users.profile.sport_competitions.title") }}
          </div>

          <div v-if="this.actual_competition.status == 'requested' && this.actual_competition.initiator.id == this.current_user.id">
            <div class="alert alert-warning" style="display: flex; align-items: center; margin-top: 24px;">
              <div class="sport-alert-item-wrap">
                <div :class="`sport-alert-item mini-${this.actual_competition.sport_code}`" style="width: 25px; height: 25px;"></div>
              </div>

              <div>
                {{ I18n.t("users.profile.sport_competitions.waiting_response") }}
              </div>
            </div>
          </div>

          <div v-if="this.actual_competition.status == 'requested' && this.actual_competition.requested_user.id == this.current_user.id">
            <div class="alert alert-warning" style="display: flex; align-items: center;">
              <div class="sport-alert-item-wrap">
                <div :class="`sport-alert-item mini-${this.actual_competition.sport_code}`" style="width: 25px; height: 25px;"></div>
              </div>

              <div>
                {{ I18n.t("users.profile.sport_competitions.user_requested_competition", { nickname: this.actual_competition.initiator.nickname}) }}
                <a href="/sport_competitions">
                  {{ I18n.t("users.profile.sport_competitions.more_info_link") }}
                </a>
              </div>
            </div>
          </div>

          <div v-else-if="this.actual_competition.status == 'in_progress'" class="main-competition" style="margin-top: 24px">
            <div class="under-section-title">
              {{ I18n.t("users.profile.sport_competitions.current_competition_title") }}
            </div>
            <div style="margin-top: 24px; display: flex;">
              <div v-if="this.actual_competition.initiator.id == this.current_user.id" style="display: flex;">
                <div style="display: flex; align-items: center;">
                  <a :href="`/users/${this.actual_competition.initiator.nickname}`" style="margin-right: 24px">
                    <user-avatar :user="actual_competition.initiator" :width="40" :height="40" :alt="actual_competition.initiator.nickname" />
                  </a>

                  <div style="font-size: 30px">
                    {{ this.actual_competition.initiated_user_wins }}
                  </div>
                </div>
                <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
                <div style="display: flex; align-items: center; margin-right: 24px;">
                  <div style="font-size: 30px">
                    {{ this.actual_competition.requested_user_wins }}
                  </div>
                  <a :href="`/users/${this.actual_competition.requested_user.nickname}`" style="margin-left: 24px">
                    <user-avatar :user="actual_competition.requested_user" :width="40" :height="40" :alt="actual_competition.requested_user.nickname" />
                  </a>
                </div>

                <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
                  <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
                    <div :class="`sport-alert-item mini-${this.actual_competition.sport_code}`" style="width: 20px; height: 20px;"></div>
                  </div>

                  <div style="font-size: 13px; color: grey; margin-top: 6px;">
                    {{ I18n.t('sport_competitions.index.till_wins', { wins_count: this.actual_competition.count_of_wins }) }}
                  </div>
                </div>
              </div>

              <div v-else style="display: flex;">
                <div style="display: flex; align-items: center;">
                  <a :href="`/users/${this.actual_competition.requested_user.nickname}`">
                    <user-avatar :user="actual_competition.requested_user" :width="40" :height="40" :alt="actual_competition.requested_user.nickname" />
                  </a>
                  <div style="font-size: 30px; margin-left: 24px;">
                    {{ this.actual_competition.requested_user_wins }}
                  </div>
                </div>
                <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
                <div style="display: flex; align-items: center; margin-right: 24px;">
                  <div style="font-size: 30px; margin-right: 24px;">
                    {{ this.actual_competition.initiated_user_wins }}
                  </div>
                  <a :href="`/users/${this.actual_competition.initiator.nickname}`">
                    <user-avatar :user="actual_competition.initiator" :width="40" :height="40" :alt="actual_competition.initiator.nickname" />
                  </a>
                </div>

                <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
                  <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
                    <div :class="`sport-alert-item mini-${this.actual_competition.sport_code}`" style="width: 20px; height: 20px;"></div>
                  </div>

                  <div style="font-size: 13px; color: grey; margin-top: 6px;">
                    {{ I18n.t('sport_competitions.index.till_wins', { wins_count: this.actual_competition.count_of_wins  }) }}
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-top: 16px; display: flex; align-items: center;">
              <div>
                <a :href="`/sport_competitions/${this.actual_competition.id}`" class="light-link">
                  {{ I18n.t('sport_competitions.index.more_info_link') }}
                </a>
              </div>
            </div>
          </div>

          <div v-if="this.user.finished_competitions_count > 0" style="margin-top: 24px;">
            <a href="/sport_competitions" class="light-link">
              {{ I18n.t("users.profile.sport_competitions.show_all_finished_for_user", { nickname: this.user.nickname }) }}
            </a>
          </div>
        </div>

        <div v-else-if="this.finished_competition" style="margin-top: 24px;">
          <div class="section-title">
            {{ I18n.t("users.profile.sport_competitions.title") }}
          </div>

          <div class="main-competition" style="margin-top: 24px;">
            <div class="under-section-title" style="display: flex; align-items: center;">
              <div>
                {{ I18n.t("users.profile.sport_competitions.latest_competition_title") }}
              </div>

              <div style="font-weight: 450; font-size: 14px;">
                <div v-if="this.finished_competition.winner_id == this.current_user.id" style="display: flex; align-items: center; margin-left: 12px;">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-party-popper"><path d="M5.8 11.3 2 22l10.7-3.79"/><path d="M4 3h.01"/><path d="M22 8h.01"/><path d="M15 2h.01"/><path d="M22 20h.01"/><path d="m22 2-2.24.75a2.9 2.9 0 0 0-1.96 3.12c.1.86-.57 1.63-1.45 1.63h-.38c-.86 0-1.6.6-1.76 1.44L14 10"/><path d="m22 13-.82-.33c-.86-.34-1.82.2-1.98 1.11c-.11.7-.72 1.22-1.43 1.22H17"/><path d="m11 2 .33.82c.34.86-.2 1.82-1.11 1.98C9.52 4.9 9 5.52 9 6.23V7"/><path d="M11 13c1.93 1.93 2.83 4.17 2 5-.83.83-3.07-.07-5-2-1.93-1.93-2.83-4.17-2-5 .83-.83 3.07.07 5 2Z"/></svg>
                  </div>

                  <div style="margin-left: 6px;font-weight: 500;color: #004e00; font-size: 13px;">
                    {{ I18n.t('users.profile.sport_competitions.you_won') }}
                  </div>
                </div>

                <div v-else style="display: flex; align-items: center; margin-left: 12px;">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-x"><circle cx="12" cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
                  </div>

                  <div style="margin-left: 6px;font-weight: bold;color: #5c0000; font-size: 13px">
                    {{ I18n.t('users.profile.sport_competitions.you_lose') }}
                  </div>
                </div>
              </div>
            </div>

            <div v-if="this.finished_competition.initiator.id == this.current_user.id" style="display: flex; margin-top: 24px;">
              <div style="display: flex; align-items: center;">
                <a :href="`/users/${this.finished_competition.initiator.nickname}`" style="margin-right: 24px">
                  <user-avatar :user="finished_competition.initiator" :width="40" :height="40" :alt="finished_competition.initiator.nickname" />
                </a>

                <div style="font-size: 30px">
                  {{ this.finished_competition.initiated_user_wins }}
                </div>
              </div>
              <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
              <div style="display: flex; align-items: center; margin-right: 24px;">
                <div style="font-size: 30px">
                  {{ this.finished_competition.requested_user_wins }}
                </div>
                <a :href="`/users/${this.finished_competition.requested_user.nickname}`" style="margin-left: 24px">
                  <user-avatar :user="finished_competition.requested_user" :width="40" :height="40" :alt="finished_competition.requested_user.nickname" />
                </a>
              </div>

              <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
                <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
                  <div :class="`sport-alert-item mini-${this.finished_competition.sport_code}`" style="width: 20px; height: 20px;"></div>
                </div>

                <div style="font-size: 13px; color: grey; margin-top: 6px;">
                  {{ I18n.t('sport_competitions.index.till_wins', { wins_count: this.finished_competition.count_of_wins }) }}
                </div>
              </div>
            </div>

            <div v-else style="display: flex;  margin-top: 24px;">
              <div style="display: flex; align-items: center;">
                <a :href="`/users/${this.finished_competition.requested_user.nickname}`" style="margin-right: 24px">
                  <user-avatar :user="finished_competition.requested_user" :width="40" :height="40" :alt="finished_competition.requested_user.nickname" />
                </a>
                <div style="font-size: 30px">
                  {{ this.finished_competition.requested_user_wins }}
                </div>
              </div>
              <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
              <div style="display: flex; align-items: center; margin-right: 24px;">
                <div style="font-size: 30px">
                  {{ this.finished_competition.initiated_user_wins }}
                </div>
                <a :href="`/users/${this.finished_competition.initiator.nickname}`" style="margin-left: 24px">
                  <user-avatar :user="finished_competition.initiator" :width="40" :height="40" :alt="finished_competition.initiator.nickname" />
                </a>
              </div>

              <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
                <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
                  <div :class="`sport-alert-item mini-${this.finished_competition.sport_code}`" style="width: 20px; height: 20px;"></div>
                </div>

                <div style="font-size: 13px; color: grey; margin-top: 6px;">
                  {{ I18n.t('sport_competitions.index.till_wins', { wins_count: this.finished_competition.count_of_wins }) }}
                </div>
              </div>
            </div>

            <div style="margin-top: 16px; display: flex; align-items: center;">
              <div style="margin-right: 16px;">
                <a :href="`/sport_competitions/new?user_id=${(this.finished_competition.initiator.id == this.current_user.id ? this.finished_competition.requested_user.id : this.finished_competition.initiator.id)}`" style="text-transform: none; color: black; font-weight: 400; text-decoration: none;" class="light-link">
                  <font-awesome-icon icon="redo" size="sm" />
                  {{ I18n.t('sport_events.retry_link') }}
                </a>
              </div>

              <div>
                <a :href="`/sport_competitions/${this.finished_competition.id}`" class="light-link">
                  {{ I18n.t('sport_competitions.index.more_info_link') }}
                </a>
              </div>
            </div>

            <current-user-competitions-year-result :currentUserId="this.current_user.id" :result="this.year_competitions_results[this.finished_competition.initiator.id == this.current_user.id ? this.finished_competition.requested_user.id : this.finished_competition.initiator.id]" v-if="this.year_competitions_results[this.finished_competition.initiator.id == this.current_user.id ? this.finished_competition.requested_user.id : this.finished_competition.initiator.id]" :opponentNickname="this.finished_competition.initiator.id == this.current_user.id ? this.finished_competition.requested_user.nickname : this.finished_competition.initiator.nickname" />
          </div>

          <div v-if="finished_competition || actual_competition" style="margin-top: 24px;">
            <a :href="`/sport_competitions?user_id=${this.user.id}`" class="light-link">
              {{ I18n.t("users.profile.sport_competitions.show_all_finished_for_user", { nickname: this.user.nickname } ) }}
            </a>
          </div>
        </div>

        <div class="user-competition-section" style="margin-top: 36px;" v-else-if="this.isCurrentUser()">
          <div class="section-title">
            {{ I18n.t("users.profile.sport_competitions.my_title") }}
          </div>

          <current-user-competitions :current_user="currentUser" v-if="isCurrentUser()" :requests_for_user="competition_requests_for_user" :requests_by_user="competition_requests_by_user" :in_progress_competitions="user_in_progress_competitions" :latest_finished_competition="current_user_last_finished_competition" :year_competitions_results="year_competitions_results" />
        </div>

        <div class="user-competition" v-else-if="!this.current_user.guest && !this.isCurrentUser()">
          <div class="section-title">
            {{ I18n.t("users.profile.sport_competitions.title") }}
          </div>

          <div style="margin-top: 24px;">
            <svg fill="#000000" height="80px" width="80px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               viewBox="0 0 487.663 487.663" xml:space="preserve">
            <g id="XMLID_112_">
              <path id="XMLID_113_" d="M485.403,406.853l-39.131-85.618c0,0,16.452-71.837,16.468-72.9c0.096-6.141-2.239-12.063-6.514-16.467
                l-77.321-79.86c2.263,3.594,4.055,7.553,5.003,11.949c2.208,10.245-0.031,20.385-5.394,28.48l11.528,47.997
                c3.276,13.66-4.389,27.799-17.716,32.423c-11.179,3.835-3.927,1.346-46.351,15.906c-7.658,4.154-12.638,11.989-13.027,20.726
                L308.178,416.1c-0.617,13.75,10.035,25.391,23.784,26.007c0.372,0.016,0.754,0.023,1.128,0.023
                c13.253,0,24.278-10.44,24.877-23.808l4.123-92.205l36.25-18.722l-2.539,10.537c-1.307,5.418-0.754,11.121,1.565,16.191
                l42.708,93.447c5.703,12.476,20.442,18.048,33.022,12.305C485.614,434.158,491.123,419.369,485.403,406.853z"/>
              <path id="XMLID_114_" d="M332.148,213.35l-0.113,11.858l-77.134,26.469c-10.845,3.715-16.621,15.527-12.898,26.38
                c2.954,8.613,11.008,14.032,19.64,14.032c2.232,0,4.511-0.365,6.739-1.136l91.022-31.228c8.323-2.856,13.945-10.644,14.026-19.444
                l0.398-42.117c-4.924,4.956-11.244,8.622-18.577,10.206L332.148,213.35z"/>
              <path id="XMLID_115_" d="M212.299,117.161c16.977,9.694,38.124,4.834,49.546-10.423c1.331,7.155,4.671,14.017,10.116,19.646
                c14.73,15.193,39.008,15.583,54.21,0.852c15.217-14.731,15.597-38.984,0.876-54.203c-14.722-15.208-38.993-15.607-54.203-0.876
                c-1.524,1.484-2.799,3.123-4.032,4.777c-1.986-10.716-8.338-20.546-18.535-26.371c-18.396-10.488-41.808-4.08-52.288,14.31
                C187.51,83.271,193.901,106.673,212.299,117.161z"/>
              <path id="XMLID_116_" d="M260.011,312.068c-0.04-0.023-66.694-41.474-66.735-41.499l23.532-41.272l9.702,14.302
                c1.42,2.091,3.333,3.698,5.354,5.126c4.331-5.72,10.35-10.262,17.651-12.768l37.533-12.882l-1.598,0.35
                c-3.854,0.827-7.69,0.34-11.032-1.16l-42.66-19.176l-33.2-14.927l79.51,17.895c3.027,0.673,6.077,0.656,8.94,0.032l64.748-13.96
                c11.211-2.409,18.332-13.456,15.915-24.675c-2.409-11.21-13.409-18.349-24.675-15.916l-60.272,12.995l-75.049-16.896l24.133,0.496
                c-1.477-1.242-3.033-2.41-4.77-3.399l-12.248-6.992c-7.603-4.332-16.621-5.476-25.066-3.164
                c-8.444,2.312-15.624,7.884-19.964,15.485l-99.302,182.65L6.804,400.105c-9.449,10.001-9.004,25.771,1.007,35.221
                c9.993,9.458,25.778,9.003,35.22-1.007l66.24-70.126c1.72-1.808,3.148-3.877,4.251-6.115l29.518-60.083
                c0.065,0.04,0.115,0.097,0.18,0.138l78.941,49.084l1.136,70.401c0.226,13.62,11.34,24.513,24.911,24.513c0.138,0,0.276,0,0.414,0
                c13.756-0.226,24.733-11.56,24.513-25.325l-1.364-83.979C271.635,324.358,267.206,316.547,260.011,312.068z"/>
            </g>
            </svg>
          </div>

          <div style="font-size: 14px;">
            {{ I18n.t('sport_competitions.index.no_competitions_with_user') }}
          </div>

          <div style="margin-top: 24px;">
            <a :href="`/sport_competitions/new?user_id=${this.user.id}`" class="btn btn-sm cta-btn edit-button" style="font-size: 14px; border: 1px solid #d8d8d8;">
              {{ I18n.t("users.profile.sport_competitions.request_competition") }}

              <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L6 5.73684L1 10" stroke="#000000" stroke-width="2"/>
              </svg>
            </a>
          </div>

          <div v-if="this.user.finished_competitions_count > 0" style="margin-top: 24px;">
            <a href="/sport_competitions" class="light-link">
              {{ I18n.t("users.profile.sport_competitions.show_all_finished_for_user", { nickname: this.user.nickname }) }}
            </a>
          </div>
        </div>

        <div class="user-partners" v-if="this.user.partners.length > 0">
          <div class="section-title">
            {{ I18n.t("users.profile.main_partners.title") }}
          </div>

          <div class="user-partner-list">
            <div class="user-partner-item" v-for="(partner, index) in this.user.partners">
              <a :href="`/users/${partner.nickname}`">
                <user-avatar :user="partner" :width="50" :height="50" />
                <div style="font-size: 12px; font-weight: 500; margin-top: 6px;">@{{ partner.nickname.substring(0,16) }}</div>
              </a>
            </div>

            <div class="show-more-partners" v-if="this.user.partners_count > 3">
              <a :href="`/users/${this.user.id}/partners`">
                <div class="more-users-link">
                  + {{ this.user.partners_count - 3 }}
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="empty-user-partners" v-else-if="this.user.partners.length == 0 && !this.isCurrentUser()">
          <div class="section-title">
            {{ I18n.t("users.profile.main_partners.title") }}
          </div>

          <div style="margin-top: 24px;">
            {{ I18n.t("users.profile.main_partners.no_partners") }}
          </div>
        </div>

        <div class="potential-user-partners" v-if="this.user.partners.length == 0 && this.currentUser.possible_partners && this.currentUser.possible_partners.length > 0 && this.isCurrentUser()">
          <div class="section-title">
            {{ I18n.t("users.profile.possible_partners.title") }}
          </div>

          <div style="display: flex; align-items: center; font-size: 13px; margin-top: 8px;">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-lightbulb"><path d="M15 14c.2-1 .7-1.7 1.5-2.5 1-.9 1.5-2.2 1.5-3.5A6 6 0 0 0 6 8c0 1 .2 2.2 1.5 3.5.7.7 1.3 1.5 1.5 2.5"/><path d="M9 18h6"/><path d="M10 22h4"/></svg>
            </div>
            <div style="color: #808080;">
              {{ I18n.t('users.profile.main_partners.notify_about_new_users') }}
            </div>
          </div>

          <div class="user-partner-list">
            <div class="user-partner-item" v-for="(partner, index) in this.currentUser.possible_partners">
              <a :href="`/users/${partner.nickname}`">
                <user-avatar :user="partner" :width="50" :height="50" />
                <div style="font-size: 12px; font-weight: 500; margin-top: 6px;">@{{ partner.nickname.substring(0,16) }}</div>
              </a>
            </div>
          </div>

          <div style="margin-top: 18px;">
            <a href="/users" class="light-link">{{ I18n.t("users.profile.possible_partners.show_more_link") }}</a>
          </div>
        </div>

        <div class="empty-user-partners" v-else-if="this.user.partners.length == 0 && this.currentUser.possible_partners && this.currentUser.possible_partners.length == 0 && this.isCurrentUser()">
          <div class="section-title">
            {{ I18n.t("users.profile.possible_partners.title") }}
          </div>
          <div style="margin-top: 12px; line-height: 1.2;">
            {{ I18n.t("users.profile.possible_partners.no_partners") }}
          </div>
        </div>

        <div class="user-sports-wrap">
          <div class="user-sports-header">
            <div class="section-title">
              {{ I18n.t("users.profile.sports.title") }}
            </div>
          </div>

          <div class="user-sports-wrap" v-if="Object.keys(this.user.sports).length > 0">
            <div class="user-sport-list">
              <a :href="`/users?sport=${sport}`" v-for="(level, sport, index) in this.user.sports">
                <div class="user-sport-item">
                  <div class="sport-item__image" :class="sport"></div>
                  <div class="sport-item__level">{{ level }}</div>
                </div>
              </a>
            </div>

            <div class="user-sports-more-link" v-if="Object.keys(this.user.sports).length > 0">
              <a :href="`/users/${this.user.id}/sports`" class="light-link">
                {{ I18n.t("sport_event.show_link") }}
              </a>
            </div>
          </div>

          <div v-else>
            <div v-if="this.isCurrentUser()" style="margin-top: 24px;">
              <a href="/user_settings/sports" class="btn cta-btn purple full-button">
                {{ I18n.t("sport_events.settings_button_set_up") }}
              </a>
            </div>

            <div style="margin-top: 24px; font-size: 14px; text-transform: lowercase;" v-else>
              {{ I18n.t("users.profile.sports.nothing_selected") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <modals-container />
  </div>
</template>

<script>
  import axios from "axios"
  import showAlert from "../../utils/alert"
  import showAvatar from "../../utils/avatar"
  import UserNewPlace from "../../components/users/modals/user_place.vue"
  import UserAvatarModal from "../../components/users/modals/user_avatar.vue"
  import TelegramSubscriptionNotificationModal from "../../components/info_modals/telegram_subscription.vue"
  import PlayStatusDescription from "../../components/users/modals/play_status_description.vue"

  export default {
    props: {
      current_user: Object,
      user: Object,
      nearest_event: Object,
      latest_finished_event: Object,
      unread_chat_messages_count: Number,
      actual_competition: Object,
      finished_competition: Object,
      map_coords: Object,
      competition_requests_for_user: Array,
      competition_requests_by_user: Array,
      user_in_progress_competitions: Array,
      current_user_last_finished_competition: Object,
      year_competitions_results: Object
    },
    data: function() {
      return {
        currentUser: this.current_user,
        userLinkActiveTab: "about",
        showStreakNotification: true,
      }
    },
    created() {
      this.$eventHub.$on("new-user-place", this.changeUserPlace)
    },
    mounted() {
      if (!this.isCurrentUser() || !this.currentUser.can_remind_telegram_subscription)
        return

      this.$modal.show(TelegramSubscriptionNotificationModal,
        {},
        {
          pivotX: (this.$mq === "sm" ? 0 : 0.5),
          width: (this.$mq === "sm" ? "100%" : 600)
        }
      )
    },
    computed: {
      fullName() {
        if (this.user.name || this.user.surname)
          return `${this.user.name ? this.user.name : ''} ${this.user.surname ? this.user.surname : ''}`;
      }
    },
    methods: {
      formattedTime(event) {
        const minutes = ('0' + event.start_at_minutes).slice(-2)
        const time = `${event.start_at_hour}:${minutes}`

        return time
      },
      changeUserPlace(emmittedCurrentUser) {
        this.currentUser = emmittedCurrentUser
      },
      imgPath(userObj) {
        return showAvatar(userObj, 150)
      },
      changeLinkActiveItem(newName) {
        this.userLinkActiveTab = newName
      },
      userLinkActiveItem(itemName) {
        if (itemName == this.userLinkActiveTab)
          return "active-item"
      },
      logoName(rank) {
        return `${rank}-medal`
      },
      isCurrentUser() {
        return this.user.id == this.currentUser.id
      },
      openNewUserAdressModal() {
        this.$modal.show(UserNewPlace,
          { 
            current_user: this.currentUser,
            default_map_coords: this.map_coords,
            onSuccess: (data) => {
              this.currentUser.current_geo = data.current_geo
              this.currentUser.street_name = data.street_name
              this.currentUser.coordinates_available = data.coordinates_available
            }
          },
          {
            height: "auto",
            pivotX: (this.$mq === "sm" ? 0 : 0.5),
            width: (this.$mq === "sm" ? "100%" : 600)
          }
        )
      },
      openUserAvatarModal() {
        if (!this.user.avatar_path)  
          return

        this.$modal.show(UserAvatarModal,
          { user: this.user },
          { height: "auto", pivotX: (this.$mq === "sm" ? 0 : 0.5), width: (this.$mq === "sm" ? "100%" : 350) }
        )
      },
      nicknameBig() {
        return !this.fullName ? 'nickname-big' : ''
      },
      updatePlayStatus(status) {
        this.currentUser.play_status = status
        this.currentUser.play_status_updated_at = new Date()

        axios
          .put(`/api/v1/users/${this.currentUser.id}`, { user: this.currentUser })
          .then(response => {
            showAlert(I18n.t("users.edit_form.success_notification"))

            this.user = response.data.user
          })
          .catch(error => {
            showAlert(
              error.response.data,
              "alert"
            )
          })
      },
      openPlayStatusModal() {
        this.$modal.show(PlayStatusDescription, { user: this.user }, { height: "auto", pivotX: (this.$mq === "sm" ? 0 : 0.5), width: (this.$mq === "sm" ? "100%" : 450) })
      },
      closeActivityStreak() {
        this.showStreakNotification = false

        this.currentUser.play_status = status
        this.currentUser.play_status_updated_at = new Date()

        axios
          .put(`/users/${this.user.id}`, { "user": { "closed_activity_streak_at": new Date() } })
          .then(response => {
          })
          .catch(error => {
            console.log(error.response.data)
            showAlert(
              error.response.data,
              "alert"
            )
          })
      }
    }
  }
</script>

<style scoped>

  .user-show {
    padding-top: 50px;
    padding-bottom: 60px;

    @media only screen and (min-width: 600px) {
      display: flex;
    }

    @media only screen and (min-width: 650px) and (max-width: 1256px) {
      padding-top: 50px;
    }
  }

  .bottom-content {
    width: 100%;
  }

  .top-content {
    @media only screen and (min-width: 600px) {
      margin-right: 24px;
    }
  }

  .user-main-info-wrap {
    min-height: 110px;

    @media only screen and (max-width: 600px) {
      margin-top: 24px;
    }
  }

  .user-main-info {
    display: flex;
    justify-content: space-between;

    padding: 0px 15px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 24px;

    @media only screen and (max-width: 600px) {
      align-items: flex-end;
    }
  }

  .desktop-notification-counter {
    position: absolute;
    top: 5px;
    right: 20px;
    background-color: rgba(212, 19, 13, 1);
    color: #fff;
    border-radius: 3px;
    padding: 1px 3px;
    font: 8px Verdana;
    height: 6px;
  }

  .user-main-info-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .user-main-info-right {
    @media only screen and (max-width: 600px) {
      min-width: 120px;
    }

    @media only screen and (min-width: 600px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .user-additional-info {
    padding: 0px 15px;
    @media only screen and (max-width: 600px) {
      margin-top: 12px;
    }
  }

  .avatar-background {
    height: 85px;
    background-color: #679FB3;
    position: relative;
  }

  .user-header {
    text-align: center;
    position: relative;
  }

  .ready-status-confirmation {
    padding: 15px 15px;
    border: 1px solid #602ac1;
    border-radius: 15px;
    margin: 0 15px;
    position: relative;
  }

  .ready-status-confirmation__title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;

    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  .ready-status-confirmation__description {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 16px;
    color: #666666;
    line-height: 1.2;

    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  .ready-status-confirmation-close-wrap {
    position: absolute;
    right: 20px;
    top: 10px;
  }

  .avatar-icon {
    border-radius: 50%;
    width: 100px;
    height: 100px;

    @media only screen and (max-width: 600px) {
      width: 80px;
      height: 80px;
      border: 2px solid white;
    }
  }

  .email-confirmation-alert {
    margin: 15px;
    font-size: 14px;
    line-height: 1.3;

    @media only screen and (max-width: 600px) {
      font-size: 13px !important;
    }
  }

  .send-email-link a {
    font-size: 14px;

    @media only screen and (max-width: 600px) {
      font-size: 13px !important;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 0px 15px;
    height: 41px;
  }

  .nickname {
    font-size: 15px;
    color: grey;
    font-weight: 450;
  }

  .nickname-big {
    @media only screen and (min-width: 600px) {
      font-size: 20px;
      color: black;
      font-weight: 550;
      margin-top: 6px;
    }
  }

  .user-description {
    font-size: 14px;
    margin-top: 24px;
    line-height: 1.2;
    max-width: 420px;
  }

  .user-rank {
    position: absolute;
    right: 15px;
    bottom: 10%;
    color: white;
  }

  .user-rank-lg {
  }

  .new-rank-percentage {
    font-size: 12px;
    text-align: center;
    margin-top: 12px;

    @media only screen and (max-width: 600px) {
      color: white;
    }
  }

  .settings-button-wrap, .invite-button-wrap {
    @media only screen and (max-width: 600px) {
      margin-top: 12px;
    }
  }

  .competitions-link a, .settings-button-wrap a {
    background: white;
    border: 1px solid #d8d8d8;
    font-size: 13px;
    font-weight: 450;
  }

  .invite-button-wrap a {
    font-size: 13px;
    font-weight: 500;
    border-radius: 10px;
    padding: 5px 12px;
  }

  .user-links {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dedede;
  }

  .user-link-item {
    padding-top: 12px;
    margin-top: -1px;
    display: flex;
    align-items: flex-end;
    height: 30px;
    cursor: pointer;
  }

  .user-link-item span {
    font-size: 14px;
    margin-left: 5px;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  .active-item {
    border-top: 1px solid black;
  }

  .user-about {
    padding-bottom: 36px;
    border-bottom: 1px solid #e6e6e6;
  }

  .user-info {
    font-size: 14px;
    margin: 0px 10px;
    margin-top: 36px;
  }

  .user-info__name {
    font-size: 22px;
    line-height: 1.19048;
    font-weight: 400;
    min-width: 80%;

    @media only screen and (max-width: 600px) {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .user-info__birthday {
    font-size: 13px;
    color: #979797;
    margin-top: 12px;
    height: 15px;
    display: flex;
    align-items: baseline;
  }

  .user-info__birthday svg {
    margin-right: 3px;
  }

  .user-info__current-place-wrap {
  }

  .user-info__current-place {
    font-size: 12px;
    font-weight: 450;

    @media only screen and (max-width: 600px) {
      font-size: 11px;
    }
  }

  .user-info__current-place__street-name {
    font-size: 12px;
    color: #979797;
    margin-top: 3px;

    @media only screen and (max-width: 600px) {
      font-size: 11px;
    }
  }

  .main-info-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }

  .nearest-sport-event {
    margin-top: 24px;
    padding: 12px 12px;
    padding-left: 15px;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 2px 12px rgb(0 40 117 / 6%);
  }

  .close-streak-notification {
    position: absolute;
    right: 12px;
    top: 5px;
    font-size: 15px;
  }

  .latest-sport-event__left-border {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    background: linear-gradient(180deg,#00b3bd,#00b3bd);
    border-top-left-radius: 4px;
  }

  .nearest-sport-event__left-border {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    background: linear-gradient(180deg,#f5ad8a,#f2976a);
    border-top-left-radius: 4px;
  }

  .nearest-sport-event__title {
    text-transform: uppercase;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .nearest-sport-event__header {
    display: flex;
    justify-content: space-between;
  }

  .nearest-sport-event__time-in-words {
    font-size: 12px;
    color: #979797;
    min-width: 100px;
    text-align: right;
  }

  .nearest-sport-event__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    align-items: center;
  }

  .nearest-sport-event__footer__date-wrap {
    margin-right: 12px;
    position: relative;
  }

  .nearest-sport-event__footer__date-wrap:after {
    content: '';
    height: 22px;
    width: 1px;
    background: #BDBDBD;
    position: absolute;
    top: 4px;
    right: -12px;
  }

  .nearest-sport-event__footer__datetime {
    display: flex;
  }

  .nearest-sport-event__footer__date-num {
    font-size: 20px;
    font-weight: 450;
  }

  .nearest-sport-event__footer__time {
    font-size: 20px;
  }

  .nearest-sport-event__footer__date-month {
    font-size: 12px;
    color: #979797;
    margin-top: 6px;
    text-transform: lowercase;
  }

  .nearest-sport-event__footer__sport {
    font-size: 12px;
    color: #979797;
    margin-top: 6px;
    text-transform: lowercase;
  }

  .nearest-sport-event__footer__wrap {
    margin-left: 12px;
  }

  .nearest-sport-event__footer__links-wrap {
    display: flex;
    align-items: center;
  }

  .nearest-sport-event__footer__links-messages {
    margin-right: 24px;
    font-size: 12px;
    position: relative;
  }

  .nearest-sport-event__footer__links-messages a {
    font-weight: 450 !important;
    text-decoration: none;
    font-size: 13px;
  }

  .nearest-sport-event__footer__links-sport-event a {
    font-size: 12px;
  }

  .nearest-sport-event__footer__chat-unread-messages {
    position: absolute;
    top: -3px;
    right: -5px;
    background-color: rgba(212, 19, 13, 1);
    color: #fff;
    border-radius: 3px;
    padding: 1px 3px;
    height: 6px;
  }

  .nearest-sport-event__footer__chat-unread-messages-counter {
    position: absolute;
    top: -7px;
    right: -10px;
    background-color: rgba(212, 19, 13, 1);
    color: #fff;
    border-radius: 3px;
    padding: 1px 3px;
    font: 8px Verdana;

    @media only screen and (max-width: 600px) {
      font: 9px Verdana;
      top: -6px;
      right: -11px;
    }
  }

  .show-more-partners a {
    text-decoration: none;
  }

  .more-users-link {
    width: 50px;
    height: 50px;
    border: 2px solid #bdbdbd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: #6f6f6f;
    font-size: 16px;
  }

  .user-sports-wrap {
    margin-top: 24px;
  }

  .user-sports-settings {
    margin-top: 12px;
  }

  .user-sports-settings a {
    font-size: 12px;
  }


  .user-sport-title-wrap {
    display: flex;
  }

  .user-sport-item {
    margin-right: 24px;
    position: relative;
    /*background-color: #6460AC;*/
    background-color: #7d79b9;
    height: 50px;
    width: 50px;
    border-radius: 10px;
  }

  .sport-item__right {
    margin-left: 12px;
  }

  .user-sports-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .user-sports-more-link {
    margin-top: 6px;
  }

  .user-sports-more-link a {
    text-transform: lowercase;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
  }

  .user-avatar {
    position: absolute;
    top: 50px;
    left: 13px;
  }

  .user-avatar-lg {
  }

  .user-sports {
  }

  .sport-item__image {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 12px;
    left: 12px;
  }

  .sport-item__name {
    font-size: 15px;
    font-weight: 500;
  }

  .sport-item__level {
    position: absolute;
    bottom: 1px;
    right: -5px;
    font-size: 11px;
    color: black;
    background-color: #F9DB85;
    font-weight: bold;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user-sport-schedules-items-block {
    display: flex;
    margin-left: 31px;
  }

  .user-sport-schedules-item__schedule {
    margin-top: 6px;
    font-size: 12px;
  }

  .user-sport-schedules-items__weekday {
    font-weight: 450;
    text-transform: lowercase;
  }

  /* common */

  .full-button {
    width: auto;
    font-weight: 450;
    font-size: 14px;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .cta-btn.purple {
    background-color: #6460ac;
    color: #fff;
  }

  .cta-btn.purple:hover {
    background-color: #5b459c;
  }

  .cta-btn.purple-light {
    background-color: #e0dfee;
    color: #6360ac;
  }

  .cta-btn.purple-light:hover {
    background-color: #bfbcdc;
  }

  .send-message-button {
    border: 1px solid #d8d8d8;
  }

  .user-partners, .potential-user-partners {
    margin-top: 36px;
  }

  .user-partner-list {
    display: flex;
    margin-top: 24px;
  }

  .user-partner-item {
    margin-right: 24px;
    text-align: center;
  }

  .user-partner-item a {
    text-decoration: none;
  }

  .user-partner-avatar-icon {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }

  .empty-user-partners {
    margin-top: 36px;
    margin-bottom: 36px;
    font-size: 14px;
  }

  /* previous css */

  .beginner-medal {
  }

  .bronze-medal {
    background: url("../../images/bronze_medal.svg");
    background-repeat: no-repeat;
    width: 30px;
    height: 36px;
    margin: 10px auto;
  }

  .silver-medal {
    background: url("../../images/silver_medal.svg");
    background-repeat: no-repeat;
    width: 30px;
    height: 36px;
    margin: 10px auto;
  }

  .gold-medal {
    background: url("../../images/gold_medal.svg");
    background-repeat: no-repeat;
    width: 40px;
    height: 46px;
    margin: 10px auto;
  }

  .user-sport-list {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
  }

  .user-sport-list a {
    margin-bottom: 12px;
  }

  .section-title {
    font-size: 18px;
    font-weight: bold;

    @media only screen and (max-width: 600px) {
      font-size: 17px;
    }
  }

  .under-section-title {
    font-size: 16px;
    font-weight: 500;

    @media only screen and (max-width: 600px) {
      font-size: 15px;
    }
  }

  .user-sport-item {

  }

  .badminton {
    background-image: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .squash {
    background-image: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .tabletennis {
    background: url("../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .volleyball {
    background: url("../../images/mini-image-volleyball.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
  }

  .user-sport-schedules-items {
    padding: 12px;
    padding-bottom: 0px;
    font-size: 13px;
  }

  .user-sport-schedules-items a {
    margin-bottom: 10px;
    margin-right: 2px;
  }

  .user-sport-schedules-items__sport {
    color: #946e1e;
    font-weight: 500;
    width: 100px;
  }

  .user-sport-schedules-items {
    display: flex;
  }

  .user-sport-schedules-item {
    margin-bottom: 10px;
  }

  .user-sport-schedules-items__weekday {
    font-weight: 500;
  }

  .sport-alert-item-wrap {
    background-color: #7d79b9;
    height: 40px;
    width: 45px;
    border-radius: 10px;
    position: relative;
    margin-right: 12px;
  }

  .sport-alert-item {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .mini-badminton {
    background-image: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tabletennis {
    background: url("../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    left: 12px !important;
  }

  .mini-squash {
    background-image: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .user-competition {
    margin-top: 24px;
  }

  .main-competition {
    padding-bottom: 24px;
    border-bottom: 1px solid #dfdfdf;

    @media only screen and (min-width: 600px) {
      max-width: 70%;
    }
  }

  .user-play-status {
    font-size: 13px;
    color: grey;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  .play_status_ready {
    color: #009900;
  }

  .play_status_not_ready {
    color: #cc0100;
  }

  .play_status_not_confirmed {
    color: #fecb00;
  }
</style>
