<template>
  <div class="sport-competition-result-form">
    <div class="message" v-if="this.message" style="margin-top: 24px; color: #b14650;">
      <font-awesome-icon icon="check" size="xs" />
      {{ this.message }}
    </div>

    <div v-if="!currentUserParticipant && !localResult.confirmed">

    </div>

    <div v-else-if="localResult.confirmed" style="margin-top: 36px; border-left: 2px solid #497d83; padding-left: 20px; width: fit-content;">
      <div v-if="!sportEventPage" style="margin-bottom: 24px;">
        <a :href="`/sport_events/${localResult.sport_event_id}`" style="font-size: 13px; color: grey; font-weight: 450;">{{ localResult.sport_event_name }}</a>
      </div>

      <div style="display: flex; align-items: center;">
        <div style="display: flex; align-items: center;">
          <div style="display: flex; text-align: center; margin-right: 24px;">
            <a :href="`/users/${localResult.requested_user.nickname}`">
              <user-avatar :user="localResult.requested_user" :width="40" :height="40" />
              <div style="margin-top: 3px;">
                @{{ localResult.requested_user.nickname }}
              </div>
            </a>
          </div>
          <div style="font-size: 30px;">
            {{ localResult.requested_user_score }}
          </div>
        </div>
        <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
        <div style="display: flex; align-items: center;">
          <div style="font-size: 30px">
            {{ localResult.initiator_user_score }}
          </div>
          <div style="display: flex; text-align: center;  margin-left: 24px;">
            <a :href="`/users/${localResult.initiator.nickname}`">
              <user-avatar :user="localResult.initiator" :width="40" :height="40" />
              <div  style="margin-top: 3px;">
                @{{ localResult.initiator.nickname }}
              </div>
            </a>
          </div>
        </div>
      </div>

      <div v-if="localResult.initiated_user_wins !== null && !!localResult.requested_user_wins !== null" style="font-size: 13px; color: grey; margin-top: 10px;">
        {{ I18n.t('sport_competitions.results.common_score') }} <span>{{ localResult.requested_user_wins }}</span> : <span>{{ localResult.initiated_user_wins }}</span>
      </div>
    </div>

    <div v-else-if="localResult.submitted_user && localResult.submitted_user.id == currentUserId" style="margin-top: 36px; border-left: 1px solid #dbdbdb; padding-left: 20px; width: fit-content;">
      <div v-if="!sportEventPage" style="margin-bottom: 12px;">
        <a :href="`/sport_events/${localResult.sport_event_id}`" style="font-size: 13px; color: grey; font-weight: 450;">{{ localResult.sport_event_name }}</a>
      </div>

      <div style="font-size: 15px;" v-if="localResult.submitted_user.id == localResult.initiator.id">
        {{ I18n.t('sport_competitions.results.you_set_score') }} <b>{{ localResult.initiator_user_score }}</b> : <b>{{ localResult.requested_user_score }}</b>
        <span v-if="localResult.requested_user_score != localResult.initiator_user_score">
          {{ localResult.initiator_user_score > localResult.requested_user_score ? I18n.t('sport_competitions.results.your_advance_score') : I18n.t('sport_competitions.results.opponnent_advance_score') }}
        </span>

      </div>

      <div style="font-size: 15px;" v-else>
        {{ I18n.t('sport_competitions.results.you_set_score') }} <b>{{ localResult.requested_user_score }}</b> : <b>{{ localResult.initiator_user_score }}</b>
        <span v-if="localResult.requested_user_score != localResult.initiator_user_score">
          {{ localResult.requested_user_score > localResult.initiator_user_score ? I18n.t('sport_competitions.results.your_advance_score') : I18n.t('sport_competitions.results.opponnent_his_advance_score') }}
        </span>
      </div>

      <div style="margin-top: 24px; font-size: 13px; color: grey">
        {{ I18n.t('sport_competitions.results.waiting_for_confirmation') }}
         <a :href="`/users/${this.userConfirmationWaiting.nickname}`">
          @{{ this.userConfirmationWaiting.nickname }}
         </a>
      </div>
    </div>

    <div v-else-if="localResult.submitted_user && localResult.submitted_user.id != currentUserId" style="margin-top: 36px; border-left: 1px solid #dbdbdb; padding-left: 20px; width: fit-content;">
      <div v-if="!sportEventPage" style="margin-bottom: 18px;">
        <a :href="`/sport_events/${localResult.sport_event_id}`" style="font-size: 13px; color: grey; font-weight: 450;">{{ localResult.sport_event_name }}</a>
      </div>

      <div v-if="localResult.submitted_user.id == localResult.initiator.id" style="font-size: 15px;">
        <a :href="`/users/${localResult.submitted_user.nickname}`">
          @{{ localResult.submitted_user.nickname }}
        </a>
        {{ I18n.t('sport_competitions.results.set_score') }}
        <b>{{ localResult.initiator_user_score }}</b> : <b>{{ localResult.requested_user_score }}</b>
        <span v-if="localResult.requested_user_score != localResult.initiator_user_score">
          {{ localResult.requested_user_score < localResult.initiator_user_score ? I18n.t('sport_competitions.results.opponnent_his_advance_score') : I18n.t('sport_competitions.results.your_advance_score') }}
        </span>
      </div>

      <div style="font-size: 15px;" v-else>
        <a :href="`/users/${localResult.submitted_user.nickname}`">
          @{{ localResult.submitted_user.nickname }}
        </a>
        {{ I18n.t('sport_competitions.results.set_score') }}

        <b>{{ localResult.requested_user_score }}</b> : <b>{{ localResult.initiator_user_score }}</b>
        <span v-if="localResult.initiator_user_score != localResult.requested_user_score">
          {{ localResult.initiator_user_score < localResult.requested_user_score ? I18n.t('sport_competitions.results.opponnent_his_advance_score') : I18n.t('sport_competitions.results.your_advance_score') }}
        </span>

      </div>

      <div style="padding-top: 24px; text-align: center;" v-if="loading">
        <img src="../../images/loading.gif" loading="lazy" style="width: 80px;">
      </div>

      <div style="display: flex; align-items: center; margin-top: 24px;" v-else>
        <div>
          <button v-on:click.prevent.stop="acceptResult()" class="btn btn-success-light btn-sm">
            {{ I18n.t("sport_competitions.buttons.sport_competition_result_accepted") }}
          </button>
        </div>

        <div style="margin-left: 12px;">
          <a href="" v-on:click.prevent.stop="declineResult()" class="text-danger" style="margin-right: 0px; font-size: 13px;">
            {{ I18n.t("sport_competitions.buttons.sport_competition_result_declined") }}
          </a>
        </div>
      </div>
    </div>

    <div v-else :class="resultWrapCss()">
      <div v-if="!sportEventPage" style="margin-bottom: 18px;">
        <a :href="`/sport_events/${localResult.sport_event_id}`" style="font-size: 13px;">{{ localResult.sport_event_name }}</a>
      </div>

      <div style="display: flex; align-items: center;" v-if="currentUserId == localResult.requested_user.id">
        <div style="display: flex; align-items: center;">
          <div style="display: flex; text-align: center; margin-right: 24px;">
            <a :href="`/users/${localResult.requested_user.nickname}`">
              <user-avatar :user="localResult.requested_user" :width="40" :height="40" :alt="localResult.requested_user.nickname" />
              <div style="margin-top: 3px;">
                @{{ localResult.requested_user.nickname }}
              </div>
            </a>
          </div>
          <div style="font-size: 30px;">
            <input v-model="requested_user_score" required="true" type="text" style="width: 40px; padding: 0;">
          </div>
        </div>
        <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
        <div style="display: flex; align-items: center;">
          <div style="font-size: 30px">
            <input v-model="initiator_score" required="true" type="text" style="width: 40px; padding: 0;">
          </div>
          <div style="display: flex; text-align: center;  margin-left: 24px;">
            <a :href="`/users/${localResult.initiator.nickname}`">
              <user-avatar :user="localResult.initiator" :width="40" :height="40" />
              <div  style="margin-top: 3px;">
                @{{ localResult.initiator.nickname }}
              </div>
            </a>
          </div>
        </div>
      </div>

      <div style="display: flex; align-items: center;" v-else>
        <div style="display: flex; align-items: center;">
          <div style="display: flex; text-align: center; margin-right: 24px;">
            <a :href="`/users/${localResult.initiator.nickname}`">
              <user-avatar :user="localResult.initiator" :width="40" :height="40" />
              <div  style="margin-top: 3px;">
                @{{ localResult.initiator.nickname }}
              </div>
            </a>
          </div>
          <div style="font-size: 30px;">
            <input v-model="initiator_score" required="true" type="text" style="width: 40px; padding: 0;">
          </div>
        </div>
        <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
        <div style="display: flex; align-items: center;">
          <div style="font-size: 30px">
            <input v-model="requested_user_score" required="true" type="text" style="width: 40px; padding: 0;">
          </div>
          <div style="display: flex; text-align: center;  margin-left: 24px;">
            <a :href="`/users/${localResult.requested_user.nickname}`">
              <user-avatar :user="localResult.requested_user" :width="40" :height="40" />
              <div style="margin-top: 3px;">
                @{{ localResult.requested_user.nickname }}
              </div>
            </a>
          </div>
        </div>
      </div>

      <div style="font-size: 12px; margin-top: 12px; font-style: italic; max-width: 80%;" v-if="!this.currentUserHomePage">
        {{ I18n.t('sport_competitions.results.score_note') }}
      </div>

      <div style="padding-top: 24px; text-align: center;" v-if="loading">
        <img src="../../images/loading.gif" style="width: 80px;">
      </div>

      <div style="margin-top: 12px;" v-else>
        <button v-on:click.prevent.stop="sendResult()" :class="submitButtonCss()">
          {{ I18n.t('sport_competitions.results.send') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import showAlert from "../../utils/alert"
  import showAvatar from "../../utils/avatar"

  export default {
    props: {
      result: Object,
      currentUserId: Number,
      sportEventPage: {
        type: Boolean,
        default: false
      },
      currentUserHomePage: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        localResult: this.result,
        initiator_score: null,
        requested_user_score: null,
        message: null,
        loading: false,
        localPage: this.pageName
      }
    },
    methods: {
      imgPath(userObj) {
        return showAvatar(userObj, 150)
      },
      sendResult() {
        if (!this.initiator_score || !this.requested_user_score) {
          return
        }

        this.loading = true

        axios
          .post(`/api/v1/sport_competitions/results`,
            {
              id: this.localResult.id,
              sport_competition_id: this.localResult.sport_competition_id,
              initiator_score: this.initiator_score,
              requested_user_score: this.requested_user_score
            }
          )
          .then(response => {
            showAlert(I18n.t("users.edit_form.success_notification"))

            this.localResult = response.data.sport_competition_result

            this.$eventHub.$emit('result-submitted', response.data)
          })
          .catch(error => {
            const response = error.response.data

            this.localResult = response.sport_competition_result
            this.message = response.message[0]
          })
          .finally(() => (this.loading = false));
      },
      acceptResult() {
        this.loading = true

        axios
          .post(`/api/v1/sport_competitions/result_accepts`,
            {
              id: this.localResult.id
            }
          )
          .then(response => {
            showAlert(I18n.t("users.edit_form.success_notification"))

            if (this.sportEventPage) {
              this.localResult = response.data.sport_competition_result
            } else {
              this.$eventHub.$emit('result-submitted', response.data)
              this.$eventHub.$emit('change-sport-competitions', response.data)
            }
          })
          .catch(error => {
            console.log(error)
            this.errors = error.response.data[0]
          })
          .finally(() => (this.loading = false));
      },
      declineResult() {
        this.loading = true

        axios
          .post(`/api/v1/sport_competitions/result_declines`,
            {
              id: this.localResult.id
            }
          )
          .then(response => {
            showAlert(I18n.t("sport_competitions.results.desclined_message"))

            if (this.sportEventPage) {
              this.localResult = response.data.sport_competition_result
            } else {
              this.$eventHub.$emit('result-submitted', response.data)
              this.$eventHub.$emit('change-sport-competitions', response.data)
            }
          })
          .catch(error => {
            this.errors = error.response.data[0]
            console.log(error)
          })
          .finally(() => (this.loading = false));
      },
      submitButtonCss() {
        if (this.currentUserHomePage && this.$mq !== "sm") {
          return "btn btn-sm btn-success button-full-width";
        } else if (this.currentUserHomePage && this.$mq === "sm") {
          return "btn btn-success button-full-width";
        }

        return "btn btn-success btn-sm";
      },
      resultWrapCss() {
        if (this.currentUserHomePage) {
          return "mt-36";
        }

        return "mt-24";
      }
    },
    computed: {
      userConfirmationWaiting() {
        return this.localResult.submitted_user.id == this.localResult.requested_user.id ? this.localResult.initiator : this.localResult.requested_user
      },
      currentUserParticipant() {
        return [this.localResult.requested_user.id, this.localResult.initiator.id].includes(this.currentUserId)
      }
    }
  }
</script>

<style scoped>
  .sport-competition-result-form {
  }

  .sport-competition-result-form a {
    font-size: 13px;
  }

  .button-full-width {
    margin-top: 12px;

    @media only screen and (max-width: 600px) {
      width: 100%;
      margin-top: 24px;
    }
  }

  .mt-24 {
    margin-top: 24px;
  }

  .mt-36 {
    margin-top: 36px;
  }

  .user-partner-avatar-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
</style>
