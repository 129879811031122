<template>
  <div class="user-avatar-wrap">
    <a :href="`/users/${user.nickname}`" style="width: fit-content;">
      <user-avatar :user="user" :width="50" :height="50" />
    </a>

    <div class="user-nickname">
      <a :href="`/users/${user.nickname}`">{{ showName(user) }}</a>
    </div>

    <div class="user-play-status-wrap" style="text-align: right;">
      <div class="user-play-status" style="cursor: pointer;" v-on:click="openPlayStatusModal()">
        <font-awesome-icon icon="circle" size="xs" :class="`play_status_${user.play_status}`" />
        {{ I18n.t(`users.play_status.${user.play_status}`) }}
      </div>
    </div>
  </div>
</template>

<script>
import showAvatar from "../../../utils/avatar"
import PlayStatusDescription from "../../../components/users/modals/play_status_description.vue"

export default {
  name: 'Avatar',
  props: {
    user: Object
  },
  data: function() {
    return {
      imageLoaded: false
    }
  },
  methods: {
    avatarPath(user) {
      return showAvatar(user, 50)
    },
    handleImageError(e) {
      e.target.src = 'path/to/fallback/image.jpg' // Add your fallback image path
    },
    handleImageLoad(user) {
      this.imageLoaded = true
    },
    showName(user) {
      if (user.nickname.includes("spotty_") && this.fullName(user))
        return this.fullName(user)

      return `@${user.nickname}`
    },
    fullName(user) {
      if (user.name || user.surname)
        return `${user.name ? user.name : ''} ${user.surname ? user.surname : ''}`;
    },
    openPlayStatusModal() {
      this.$modal.show(PlayStatusDescription, { user: this.user }, { height: "auto", pivotX: (this.$mq === "sm" ? 0 : 0.5), width: (this.$mq === "sm" ? "100%" : 450) })
    },
  }
}
</script>

<style scoped>
  .user-avatar-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .avatar-placeholder {
    border-radius: 30px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eeeded;
    position: absolute;
    top: 0px;
  }

  .user-avatar img {
    border-radius: 30px;
    width: 50px;
    height: 50px;
    box-shadow: inset 0 2px 4px 0 hsl(0deg 0% 0% / 20%);
  }

  .user-nickname {
    margin-top: 6px;
  }

  .user-nickname a {
    text-decoration: none;
  }

  .user-play-status {
    font-size: 13px;
    min-width: 125px;
    text-align: left;
    margin-top: 6px;
    color: grey;

    @media only screen and (max-width: 600px) {
      font-size: 13px;
    }
  }
</style>
