<template>
  <div class="sport-competition">
    <a class="back-link" :href="`/sport_competitions`" style="text-decoration: none;">
      <div class="back-link-wrap" v-if="$mq == 'lg'">
        <font-awesome-icon icon="arrow-left" size="sm" />
        <span style="font-size: 14px; font-weight: 400;">{{ I18n.t("common.buttons.back") }}</span>
      </div>
    </a>

    <div style="margin: 24px 0px;">
      <div style="display: flex; align-items: center; padding-bottom: 24px; border-bottom: 1px solid #e7e7e7;">
        <div style="display: flex; align-items: center;">
          <a :href="`/users/${localCompetition.requested_user.nickname}`" style="margin-right: 24px; text-align: center;">
            <user-avatar :user="localCompetition.requested_user" :width="40" :height="40" :alt="localCompetition.requested_user.nickname" />
            <div style="margin-top: 3px;">
              @{{ localCompetition.requested_user.nickname }}
            </div>
          </a>
          <div style="font-size: 30px">
            {{ localCompetition.requested_user_wins }}
          </div>
        </div>
        <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
        <div style="display: flex; align-items: center; margin-right: 24px;">
          <div style="font-size: 30px">
            {{ localCompetition.initiated_user_wins }}
          </div>
          <a :href="`/users/${localCompetition.initiator.nickname}`" style="margin-left: 24px; text-align: center;">
            <user-avatar :user="localCompetition.initiator" :width="40" :height="40" :alt="localCompetition.initiator.nickname" />
            <div style="margin-top: 3px;">
              @{{ localCompetition.initiator.nickname }}
            </div>
          </a>
        </div>

        <div style="padding-left: 24px; border-left: 1px solid #dcdcdc;">
          <div class="sport-alert-item-wrap" style="margin-right: 24px; width: 40px;">
            <div :class="`sport-alert-item mini-${localCompetition.sport_code}`" style="width: 20px; height: 20px;"></div>
          </div>

          <div style="font-size: 13px; color: grey; margin-top: 6px;">
            {{ I18n.t('sport_competitions.index.till_wins', { wins_count: localCompetition.count_of_wins }) }}
          </div>
        </div>
      </div>

      <div class="results">
        <div :key="result.id" v-for="result in localCompetition.finished_results" v-if="localCompetition.finished_results.length > 0" :class="resultClass(result)">
          <div v-if="" style="margin-bottom: 24px;">
            <a :href="`/sport_events/${result.sport_event_id}`" style="font-size: 13px; color: grey; font-weight: 450;">{{ result.sport_event_name }}</a>
          </div>

          <div style="display: flex; align-items: center;">
            <div style="display: flex; align-items: center;">
              <div style="display: flex; text-align: center; margin-right: 24px;">
                <a :href="`/users/${result.requested_user.nickname}`">
                  <user-avatar :user="result.requested_user" :width="40" :height="40" :alt="result.requested_user.nickname" />
                </a>
              </div>
              <div style="font-size: 25px;">
                {{ result.requested_user_score }}
              </div>
            </div>
            <div style="display: flex; align-items: center; margin-right: 6px; margin-left: 6px;">:</div>
            <div style="display: flex; align-items: center;">
              <div style="font-size: 25px">
                {{ result.initiator_user_score }}
              </div>
              <div style="display: flex; text-align: center;  margin-left: 24px;">
                <a :href="`/users/${result.initiator.nickname}`">
                  <user-avatar :user="result.initiator" :width="40" :height="40" :alt="result.initiator.nickname" />
                </a>
              </div>
            </div>
          </div>

          <div v-if="result.initiated_user_wins !== null && !!result.requested_user_wins !== null" style="font-size: 13px; color: grey; margin-top: 10px;">
            {{ I18n.t('sport_competitions.results.common_score') }} <span>{{ result.requested_user_wins }}</span> : <span>{{ result.initiated_user_wins }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      competition: Object,
      current_user: Object
    },
    data() {
      return {
        localCompetition: this.competition
      }
    },
    created() {
      this.$eventHub.$on("change-sport-competitions", this.resetSportCompetition)
    },
    methods: {
      resetSportCompetition(newCompetition) {
        console.log("NEW competition:", newCompetition)
        this.localCompetition = newCompetition.sport_competition
      },
      resultClass(result) {
        if (result.initiated_user_wins !== null || !!result.requested_user_wins !== null)
          return 'result';

        if (result.initiated_user_wins == count_of_wins || result.requested_user_wins == count_of_wins)
          return 'result result-last'

        return 'result'
      }
    }
  }
</script>

<style scoped>
  .sport-competition {
    padding: 0px 15px;
    padding-top: 65px;

    @media only screen and (max-width: 600px) {
      padding-bottom: 24px;
    }
  }

  .sport-competition a {
    font-size: 13px;
  }

  .results {
    position: relative;
  }

  .result {
    margin-top: 24px;
    padding-left: 12px;
    border-left: 1px solid #140257;
  }

  .result-last {
    border-left: 2px solid green;
  }

  .user-partner-avatar-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .user-partner-avatar-icon--small {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  .sport-alert-item-wrap {
    background-color: #7d79b9;
    height: 40px;
    width: 45px;
    border-radius: 10px;
    position: relative;
    margin-right: 12px;
  }

  .sport-alert-item {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .mini-badminton {
    background-image: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-squash {
    background-image: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .mini-tabletennis {
    background: url("../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    left: 12px !important;
  }
</style>
