<template>
  <div class="container" :class="this.showBlock === 'chat_messages' ? 'chat-messages-container' : ''"> 
    <div class="chats-list" v-if="userChats.length > 0 && (['lg', 'md'].includes(this.$mq) || this.showBlock === 'chat_list')">
      <div class="chat-item-header">
        <div class="chat-item-header-title">
          {{ I18n.t('chats.title') }}
        </div>
      </div>
      <a v-for="chat in userChats" class="chat-item-link" v-on:click.prevent="setActiveChat(chat.chat_uuid)" :href="`/messages/${chat.chat_uuid}`" v-if="chat.latest_message || !chat.conversation_with_user">
        <div class="chat-item" :class="{ 'active-chat': chat.chat_uuid === activeChatUuid }">
          <div class="latest-message-author-avatar" v-if="chat.conversation_with_user">
            <user-avatar :user="chat.conversation_with_user" :width="40" :height="40"/>
          </div>

          <div v-if="chat.sport_event" class="user-sport-item">
            <div class="sport-item__image" :class="chat.sport_event.sport_code"></div>
          </div>
          <div style="width: 100%;">
            <div class="chat-item-info-top">
              <div class="chat-info">
                <div class="chat-name" v-if="chat.conversation_with_user">
                  {{ chatTitleWithMaxLength(chat) }}
                </div>
                <div class="chat-name" style="font-size: 14px;" v-else>
                  {{ chatTitleWithMaxLength(chat) }}
                </div>
              </div>

              <div class="chat-item-created-at" v-if="chat.latest_message">
                {{ chat.latest_message.created_at_formatted }}
              </div>
            </div>

            <div class="chat-item-info-bottom">
              <div class="latest-message" v-if="chat.latest_message">
                {{ latestMessage(chat) }}
              </div>
              <div class="latest-message" style="font-size: 13px; font-style: italic;" v-else>
                {{ I18n.t('chats.no_messages') }}
              </div>

              <div class="chat-item-unread-messages" v-if="chat.unread_messages_count > 0">
                {{ chat.unread_messages_count }}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="chats-list-empty" v-else-if="userChats.length === 0">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <div style="margin-bottom: 16px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-message-circle-off"><path d="M20.5 14.9A9 9 0 0 0 9.1 3.5"/><path d="m2 2 20 20"/><path d="M5.6 5.6C3 8.3 2.2 12.5 4 16l-2 6 6-2c3.4 1.8 7.6 1.1 10.3-1.7"/></svg>
        </div>
        <div style="font-size: 15px; font-weight: 500; margin-bottom: 8px; color: #808080">
          {{ I18n.t('chats.no_active_chats') }}
        </div>
        <div>
          <a href="/users" class="blue-link">{{ I18n.t('chats.find_users') }}</a>
        </div>
      </div>
    </div>
    <div class="chat-messages" v-if="this.activeChatUuid && (['lg', 'md'].includes(this.$mq) || this.showBlock === 'chat_messages')">
      <chat-messages 
        :key="this.activeChatUuid"
        :messages="currentChatMessages" 
        :participants="currentChat ? currentChat.participants : []" 
        :current_user="current_user"
        :chat="currentChat"
        :loadMessages="loadMessages"
        :participants_online_ids="activeChatParticipantsOnlineIds"
        :back_link="active_chat_uuid ? back_link : ''"
      />
    </div>
    <div class="empty-chat-messages" v-else-if="!this.activeChatUuid && ['lg', 'md'].includes(this.$mq)">
      {{ I18n.t('chats.choose_chat') }}
    </div>
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    props: {
      user_chats: { type: Array, default: [] },
      active_chat_uuid: String,
      chat_messages: Array,
      current_user: Object,
      current_user_uuid: String,
      active_chat_participants_online_ids: Array,
      back_link: String
    },
    data() {
      return {
        currentChatMessages: this.chat_messages,
        activeChatUuid: this.active_chat_uuid,
        activeChatParticipantsOnlineIds: this.active_chat_participants_online_ids,
        userChats: this.user_chats,
        loadMessages: false,
        showBlock: this.active_chat_uuid ? 'chat_messages' : 'chat_list'
      }
    },
    created() {
      this.$eventHub.$on('message-added', (message) => {
        const index = this.userChats.findIndex(chat => chat.chat_uuid === message.chat_uuid);

        if (index !== -1) {
          this.$set(this.userChats[index], 'latest_message', {
          ...message,
            created_at_date: message.created_at_time
          });
        }
      })

      this.$eventHub.$on('show-chat-list', () => {
        this.showBlock = 'chat_list'
        this.activeChatUuid = null
      })
    },
    channels: {
      ChatsListChannel: {
        connected() { console.log('I am connected to ChatsListChannel.') },
        rejected() {},
        received(data) {
          console.log('data received', data)

          if (data.new_user_chat && !this.userChats.find(chat => chat.chat_uuid === data.new_user_chat.chat_uuid)) {
            this.userChats = [data.new_user_chat, ...this.userChats];
          }

          if (data.new_user_chat_message) {
            if (data.new_user_chat_message.chat_uuid === this.activeChatUuid) {
              return;
            }

            const index = this.userChats.findIndex(chat => chat.chat_uuid === data.new_user_chat_message.chat_uuid);
            if (index !== -1) {
              this.$set(this.userChats[index], 'latest_message', data.new_user_chat_message.message);
              const currentUnread = this.userChats[index].unread_messages_count;
              this.$set(this.userChats[index], 'unread_messages_count', currentUnread + 1);
            }
          }
        },
        disconnected() {
          console.log('I am disconnected.')
        }
      }
    },
    mounted() {
      this.$cable.subscribe({ channel: "ChatsListChannel" });

      document.addEventListener("visibilitychange", () => {
        console.log('visibilitychange')
        const favicon = document.querySelectorAll('[rel="icon"]')[0]
        
        if (favicon) {
          favicon.setAttribute("href", require("images/favicon.ico"))
        }

        if (document.hidden) {
          console.log('document hidden')

          this.$cable.perform({
            channel: "ChatChannel",
            action: "tab_hidden",
            data: {
              chat_uuid: this.activeChatUuid
            }
          })
        } else {
          console.log('document active')

          this.$cable.perform({
            channel: "ChatChannel",
            action: "tab_active",
            data: {
              chat_uuid: this.activeChatUuid
            }
          })
        }
      });
    },
    unmounted() {
      this.$cable.unsubscribe({ channel: "ChatsListChannel" });
      document.removeEventListener("visibilitychange", () => {})
    },
    beforeDestroy() {
      this.$eventHub.$off('message-added');
      this.$eventHub.$off('show-chat-list');
    },
    methods: {
      latestMessage(chat) {
        if (!chat.latest_message) {
          return ''
        }

        let prependText = '';
        if (chat.latest_message.author_id === this.current_user.id) {
          prependText = I18n.t('chats.prepend_message_text')
        }

        const maxLength = this.$mq == 'sm' ? 70 : 100;

        const messageBody = chat.latest_message.body.length > maxLength 
          ? chat.latest_message.body.substring(0, maxLength) + '...'
          : chat.latest_message.body;

        return prependText + messageBody
      },
      chatTitleWithMaxLength(chat) {
        const maxLength = this.$mq == 'sm' ? 40 : 32;

        if (chat.title.length > maxLength) {
          return chat.title.substring(0, maxLength) + '...'
        }

        return chat.title
      },
      setActiveChat(chatUuid) {
        this.loadMessages = true
        this.$eventHub.$emit('skip-mobile-header')

        const newUrl = `/messages/${chatUuid}`;
        window.history.pushState({}, '', newUrl);

        this.showBlock = 'chat_messages'
        this.activeChatUuid = chatUuid
        
        axios
          .get(
            "/api/v1/chat_messages",
            { params: { chat_uuid: chatUuid } }
          )
          .then(response => {
            const { messages, participants_online_ids } = response.data

            this.currentChatMessages = messages;
            this.activeChatParticipantsOnlineIds = participants_online_ids;
            
            this.loadMessages = false;

            const index = this.userChats.findIndex(chat => chat.chat_uuid === chatUuid);
            this.$set(this.userChats[index], 'unread_messages_count', 0);
          })
      }
    },
    computed: {
      currentChat() {
        return this.userChats.find(chat => chat.chat_uuid === this.activeChatUuid)
      },
      rerenderKey() {
        return `${this.activeChatUuid}-${this.currentChatMessages[0] ? this.currentChatMessages[0].id : -1}`
      }
    }
  }
</script>

<style scoped>
  .container {
    max-width: 1000px;
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    background: #fff;
    box-shadow: rgba(0, 40, 117, 0.1) 0px 2px 8px;
    
    @media (min-width: 760px) {
      display: flex;
      margin-top: 50px;
    }

    @media (max-width: 760px) {
      padding-top: 60px;
    }
  }

  .chat-messages-container {
    @media (max-width: 760px) {
      padding-top: 0px;
    }
  }

  .chats-list {
    width: 480px;
    padding: 16px 0px;
    border-right: 1px solid #e0e0e0;
    overflow-y: scroll;

    @media (max-width: 760px) {
      width: 100%;
      border-right: none;
    }
  }

  .chats-list-empty {
    width: 480px;
    padding: 16px 0px;
    border-right: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 760px) {
      width: 100%;
      border-right: none;
      margin-top: 100px;
    }
  }

  .chats-list-empty a {
    @media (max-width: 760px) {
      font-size: 15px;
    }
  }

  .chat-item-link {
    text-decoration: none;  
    font-weight: 400;
    cursor: pointer;
  }

  .chat-item {
    display: flex;
    gap: 10px;
    padding: 16px;
    height: 85px;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
  }

  .active-chat {
    background-color: #edf1f9;
  }

  .chat-name {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  .chat-item-created-at {
    font-size: 11px;
    color: #808080;
    text-align: right;
  }

  .chat-item-unread-messages {
    padding: 5px;
    background-color: #dbdbdb;
    width: 25px;
    color: #8b8b8b;
    border-radius: 20px;
    height: 25px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-top: 12px;
    font-weight: 500;
  }

  .latest-message {
    line-height: 1.2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 90%;
  }

  .chat-messages {
    width: 100%;
    height: 100%;

    max-width: 615px;
    overflow-y: hidden;
  }

  .empty-chat-messages {
    width: 100%;
    height: 100%;
    max-width: 615px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: #808080;
  }

  .chat-item-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
    padding-left: 16px;
  }

  .latest-message-author-avatar img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .blue-link {
    font-size: 14px;
    color: #679fb3;
    font-weight: 450;
    text-decoration: none;
    cursor: pointer;
  }

  .sport-item__image {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 10px;
    left: 8px;

    @media only screen and (max-width: 600px) {
      height: 20px;
      width: 20px;
      top: 8px;
      left: 8px;
    }
  }

  .not-viewed-message {
    margin-right: 6px;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
  }

  .chat-item-info-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chat-item-info-bottom {
    display: flex;
    justify-content: space-between;
  }

  .user-sport-item {
    position: relative;
    background-color: #7d79b9;
    height: 40px;
    width: 45px;
    border-radius: 10px;

    @media only screen and (max-width: 600px) {
    }
  }

  .badminton {
    background-image: url("../../images/mini-image-badminton.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .squash {
    background-image: url("../../images/mini-image-squash.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .football {
    background: url("../../images/mini-image-football.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .basketball {
    background: url("../../images/mini-image-basketball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .tennis {
    background: url("../../images/mini-image-tennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .tabletennis {
    background: url("../../images/mini-image-tabletennis.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .volleyball {
    background: url("../../images/mini-image-volleyball.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }
</style>
