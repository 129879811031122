<template>
    <div class="chat-container" :key="`${chat ? chat.chat_id : -1}-${currentMessages[0] ? currentMessages[0].id : -1}`">
      <div class="chat">
        <div class="chat__topic">
          <div class="arrow-left-link-wrap" v-if="['sm'].includes($mq)">
            <a :href="back_link || '/#'" @click="handleBackLink" style="width: 30px; height: 30px; margin-top: 15px; display: block; margin-right: -15px;">
              <svg width="9px" height="16px" viewBox="0 0 9 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <defs></defs>
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Icons" transform="translate(-217.000000, -364.000000)">
                          <g id="Icon/24x24/Arrow-left" transform="translate(194.000000, 360.000000)">
                              <path d="" id="Combined-Shape" stroke="#2D2F33" stroke-width="1"></path>
                              <path d="M7,5 L8,5 L8,6 L7,6 L7,5 Z M6,6 L7,6 L7,7 L6,7 L6,6 Z M5,7 L6,7 L6,8 L5,8 L5,7 Z M4,8 L5,8 L5,9 L4,9 L4,8 Z M3,9 L4,9 L4,10 L3,10 L3,9 Z M2,10 L3,10 L3,11 L2,11 L2,10 Z M1,11 L2,11 L2,12 L1,12 L1,11 Z M2,12 L2,13 L1,13 L1,12 L2,12 Z M3,13 L3,14 L2,14 L2,13 L3,13 Z M4,14 L4,15 L3,15 L3,14 L4,14 Z M5,15 L5,16 L4,16 L4,15 L5,15 Z M6,16 L6,17 L5,17 L5,16 L6,16 Z M7,17 L7,18 L6,18 L6,17 L7,17 Z M8,18 L8,19 L7,19 L7,18 L8,18 Z" id="Combined-Shape" fill="#2D2F33" fill-rule="evenodd"></path>
                          </g>
                          <polygon id="Path-4" fill="#2D2F33" points="224.649994 364.649994 224.304504 364.998749 224 364.998749 224.009186 365.288879 223.301636 365.99942 223.001968 365.99942 223.001968 366.289093 222.295471 367 222 367 222 367.294357 221.302032 368 221.000061 368 221.000061 368.288391 220.297668 368.999664 220 368.999664 220.008133 369.286789 219.297028 370.000275 218.999863 370.000275 218.999863 370.292999 218.289719 371.001312 218.004028 371.001312 218.004028 371.288849 217.30069 371.993088 218.004028 372.704346 218.004028 373.000275 218.289719 373.000275 218.999863 373.706818 218.999863 374.003616 219.297028 374.003616 220.008133 374.714508 220.008133 375.000931 220.297668 375.000931 221.000061 375.709366 221.000061 375.998764 221.302032 375.998764 222.007538 376.710724 222.007538 377.004181 222.295471 377.004181 223.001968 377.707062 223.001968 377.99646 223.301636 377.99646 224 378.701141 224 378.999619 224.285019 378.999619 224.64473 379.347107 225.008133 378.999619 225.355621 378.64621 225 378.304428 225 377.99646 224.710602 377.99646 224 377.303772 224 377.004181 223.712738 377.004181 223.001968 376.293365 223.001968 375.998764 222.706772 375.998764 222 375.295761 222 375.000931 221.714584 375.000931 221.000061 374.292267 221.000061 374.003616 220.707306 374.003616 220 373.291855 220 373.000275 219.714752 373.000275 218.999863 372.288925 218.999863 371.708542 219.714752 371.001312 220 371.001312 220.008133 370.699417 220.707306 370.000275 221.000061 370.000275 221.000061 369.698395 221.714584 368.999664 222 368.999664 222.007538 368.706406 222.706772 368 223.001968 368 223.001968 367.695847 223.712738 366.992737 224 366.992737 224 366.705795 224.710602 365.99942 225 365.99942 225 365.709991 225.355621 365.353943"></polygon>
                      </g>
                  </g>
              </svg>
            </a>
          </div>

          <div class="chat__title">
            <div v-if="chat && chat.sport_event" style="display: flex; align-items: center;">
              <div v-if="this.bodyType == 'messages'" style="display: flex; align-items: center;">
                <div class="user-sport-item">
                  <div class="sport-event-icon" :class="chat.sport_event.sport_code"></div>
                </div>
                <div>
                  <div style="font-size: 12px; font-weight: 600; color: #000;">
                    <a :href="sportEventLink()" style="font-size: 13px;">{{ chat.sport_event.name }}</a>
                  </div>
                  <div @click.stop="changeBodyType()" style="cursor: pointer; margin-top: 4px;">
                    {{ participants.length }} {{ I18n.t("chat.members", { count: participants.length }) }}
                  </div>
                </div>
              </div>

              <div v-if="this.bodyType == 'users'" style="display: flex; align-items: center; color: #000; width: 100%;">
                <div @click.stop="changeBodyType()" style="cursor: pointer; color: #679fb3; font-weight: 400;" v-if="!['sm'].includes($mq)">
                  <font-awesome-icon icon="arrow-left" size="sm" />
                  {{ I18n.t("common.buttons.back") }}
                </div>
                <div class="chat-title-members">
                  <font-awesome-icon icon="user-alt" size="sm" />
                  {{ I18n.t("chat.members_title") }}
                </div>
              </div>
            </div>
            <div v-else-if="chat && chat.conversation_with_user" style="display: flex; align-items: center;">
              <user-avatar :user="chat.conversation_with_user" :width="40" :height="40" :online="participantsOnlineIds.includes(chat.conversation_with_user.id)" />
              <div style="margin-left: 8px; color: #000; font-size: 14px; font-weight: 600;">
                <a :href="userLink(chat.conversation_with_user)">{{ chat.conversation_with_user.nickname }}</a>
              </div>
            </div>
          </div>
  
          <div class="">
          </div>
        </div>
  
        <div class="chat__users__block" v-if="bodyType == 'users'">
          <div class="chat__users__wrap">
            <div class="chat__user" v-for="participant in currentParticipants">
              <div class="chat__user-avatar">
                <a :href="userLink(participant)">
                  <div style="position: relative; width: 40px; margin: auto;">
                    <user-avatar :user="participant" :width="40" :height="40" :online="isUserOnline(participant)" />
                  </div>
                </a>
              </div>
              <div class="chat__user-name">
                <a :href="userLink(participant)">@{{ participant.nickname }}</a>
              </div>
            </div>
          </div>
        </div>
  
        <div class="chat__messages" ref="scrollList" v-if="bodyType == 'messages' && Object.keys(groupedMessages).length > 0 && !loadMessages">
          <div class="chat__load-previous-messages" v-if="currentMessages.length >= 50 && showLoadPreviousMessagesButton">
            <a href="" v-on:click.prevent="loadPreviousMessages()">
              {{ I18n.t("chat.previous_messages_link") }}
            </a>
          </div>
          <div v-for="(dateMessages, date) in groupedMessages">
            <div class="chat__messages_date">{{ date }}</div>
            <chat-message v-for="message, i in dateMessages" :message="message" :full_message="showFullMessage(dateMessages, i)" :author="messageAuthor(message)" :highlightUser="messageAuthor(message).id == highlight_user_id"  v-if="!!messageAuthor(message)" />
          </div>
        </div>

        <div v-if="loadMessages">
          <div class="chat__messages_date">
            <img src="../../images/loading_dots.gif" height="80" width="150" loading="lazy">
          </div>
        </div>
  
        <div class="empty-messages" v-else-if="bodyType == 'messages' && Object.keys(groupedMessages).length == 0">
          <div>
            <img class="avatar" src="../../images/message2.svg" height="100" width="100" loading="lazy">
          </div>
  
          {{ I18n.t("chat.no_messages") }}
        </div>
  
        <div class="typing-wrap" v-if="this.typing.length > 0">
          <div v-for="nickname in typing" class="typing-item">{{ nickname }}</div>
          <div>{{ I18n.t("chat.typing") }}...</div>
        </div>
  
        <div class="chat__input-wrapper" style="position: relative;">
          <textarea class="chat__input" id="messages-input" ref="roomTextarea" v-model="currentMessage" @input="onChangeInput" @keydown.esc="escapeTextarea" v-if="$mq === 'sm'" >
          </textarea>
  
          <textarea class="chat__input" id="messages-input" ref="roomTextarea" @keydown="sendMessageDesktop" @input="onChangeInput" v-model="currentMessage" :placeholder="I18n.t('chat.placeholder')" @keydown.esc="escapeTextarea" v-if="$mq !== 'sm'" >
          </textarea>
  
          <div style="display: flex; align-items: center; position: relative;">
            <emoji-picker @emoji="insert">
              <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
                </svg>
              </div>
              <div class="emoji-picker" slot="emoji-picker" slot-scope="{ emojis, insert, display }">
                <div>
                  <div>
                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                      <h5>{{ category }}</h5>
                      <div>
                        <span
                          v-for="(emoji, emojiName) in emojiGroup"
                          :key="emojiName"
                          @click="insert(emoji)"
                          :title="emojiName"
                        >{{ emoji }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </emoji-picker>
  
            <div @click.stop.prevent="sendMessageMobile" v-if="$mq === 'sm'" >
              <div v-if="this.currentMessageExists" id="icon-send" class="send-icon-svg">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="40" height="40" viewBox="0 0 35 35" ><path id="vac-icon-send" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path><!----></svg>
              </div>
  
              <div v-else id="icon-disabled" class="send-icon-svg">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="40" height="40" viewBox="0 0 35 35"><path id="vac-icon-send-disabled" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path><!----></svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import axios from "axios"
    import _ from "lodash"
    import showAvatar from "../../utils/avatar"
  
    export default {
      props: {
        chat: Object,
        highlight_user_id: { type: Number, default: null },
        current_user: Object,
        participants: Array,
        messages: Array,
        participants_online_ids: Array,
        showBackButton: { type: Boolean, default: false },
        loadMessages: { type: Boolean, default: false },
        back_link: String
      },
      data() {
        return {
          activeChat: this.chat,
          currentMessage: '',
          currentMessages: this.messages,
          currentParticipants: this.participants,
          participantsOnlineIds: this.participants_online_ids,
          typing: [],
          typingCounter: 0,
          showLoadPreviousMessagesButton: true,
          sendingMessage: false,
          keepKeyboardOpen: false,
          search: '',
          lastTypedAt: null,
          bodyType: "messages",
          groupChat: !!this.sport_event_id,
        }
      },
      watch: {
        messages(newVal) {
          this.currentMessages = newVal
        }
      },
      channels: {
        ChatChannel: {
          connected() {
            console.log('I am connected.')
          },
          rejected() {},
          received(data) {
            if (data.message && data.message.author_id != this.current_user.id) {
              this.pushMessage(data.message)
  
              if (document.hidden) {
                const favicon = document.querySelectorAll('[rel="icon"]')[0]
                favicon.setAttribute("href", require("images/favicon_pinned.ico"))
              }
            }
  
            if (data.typing) {
              this.typing = data.typing.filter(x => !data.typing.includes(this.current_user.nickname))

              setTimeout(() => {
                this.typing = []
              }, 5000);
            }
  
            if (data.user_ids) {
              this.participantsOnlineIds = data.user_ids
  
              if (data.user_ids.length > 1)
                this.currentMessages.filter(m => !m.viewed).forEach(m => m.viewed = true)
            }
          },
          disconnected() {
            console.log('I am disconnected.')
          }
        }
      },
      mounted() {
        this._scrollDown()
        this.$cable.subscribe({ channel: "ChatChannel", chat_uuid: this.activeChat.chat_uuid });

        document.getElementById("messages-input").addEventListener('keyup', (event) => {
          if (this.lastTypedAt && new Date() - this.lastTypedAt < 3000) {
            return;
          }

          this.lastTypedAt = new Date();

          // Triggers when a key is released
          if (this.participantsOnlineIds.length > 1) {
            this.$cable.perform({
              channel: "ChatChannel",
              action: "typing",
              data: {
                nickname: this.current_user.nickname,
                chat_uuid: this.chat.chat_uuid
              }
            })
          }

          this.typingCounter = this.typingCounter + 1;

          setTimeout(() => {
            this.stopTyping(this.typingCounter)
          }, 3000);
        });
  
        $("#messages-input").click(function() {
          const el = document.getElementById("messages-input")
          el.focus();
          el.scrollIntoView();
        //   const el = document.getElementById("messages-input")
        //   el.focus();
        //   if (typeof window.getSelection != "undefined"
        //           && typeof document.createRange != "undefined") {
        //     var range = document.createRange();
        //     range.selectNodeContents(el);
        //     range.collapse(false);
        //     var sel = window.getSelection();
        //     sel.removeAllRanges();
        //     sel.addRange(range);
        //   } else if (typeof document.body.createTextRange != "undefined") {
        //     var textRange = document.body.createTextRange();
        //     textRange.moveToElementText(el);
        //     textRange.collapse(false);
        //     textRange.select();
        //   }
        })
        if (this.$mq !== 'sm') {
          $("#messages-input").click()
        }
      },
      updated() {
        this.$nextTick(this._scrollDown())

        if (this.$mq !== 'sm') {
          $("#messages-input").focus()
        }
      },
      created() {
        this.$eventHub.$on("retryMessage", (messageId) => {
          this.retryMessage(messageId)
        })
      },
      beforeDestroy() {
        this.$eventHub.$off("retryMessage")
      },
      computed: {
        groupedMessages() {
          return _.groupBy(this.currentMessages, "created_at_date")
        },
        groupedParticipants() {
          return _.groupBy(this.currentParticipants, "id")
        },
        sendButtonClassName() {
          if (this.currentMessage) {
            return "chat__send-button-opacity"
          }
  
          return ""
        },
        currentMessageExists() {
          return this.currentMessage.length > 0
        }
      },
      methods: {
        handleMessageInput(e) {
          this.currentMessage = e.target.innerHTML
        },
        pushMessage(message) {
          this.currentMessages.push(message);
          this.$eventHub.$emit('message-added', message)
        },
        _scrollDown () {
          if (!this.$refs.scrollList) {
            return
          }
  
          this.$refs.scrollList.scrollTop = this.$refs.scrollList.scrollHeight
        },
        stopTyping(counter) {
          if (counter < this.typingCounter)
            return
  
          this.$cable.perform({
            channel: "ChatChannel",
            action: "stop_typing",
            data: {
              nickname: this.current_user.nickname,
              chat_uuid: this.activeChat.chat_uuid
            }
          })
        },
        getTextareaRef() {
          return this.$refs.roomTextarea
        },
        onChangeInput() {
          if (this.getTextareaRef()?.value || this.getTextareaRef()?.value === '') {
            this.message = this.getTextareaRef()?.value
          }
          this.keepKeyboardOpen = true
          this.resizeTextarea()
        },
        resizeTextarea() {
          const el = this.getTextareaRef()
  
          if (!el) return
  
          const padding = window
            .getComputedStyle(el, null)
            .getPropertyValue('padding-top')
            .replace('px', '')
  
          el.style.height = 0
          el.style.height = el.scrollHeight - padding * 2 + 'px'
        },
        resetTextareaSize() {
          if (this.getTextareaRef()) {
            this.getTextareaRef().style.height = '20px'
          }
        },
        handleBackLink(e) {
          if (this.bodyType == "users") {
            e.preventDefault();
            this.bodyType = "messages";
            return;
          }

          if (!this.back_link) {
            e.preventDefault();
            this.$eventHub.$emit('show-chat-list')
            this.$eventHub.$emit('show-mobile-header')
            window.history.pushState({}, '', '/messages');
          }
        },
        escapeTextarea() {
          this.resetTextareaSize()
          this.currentMessage = ''
        },
        getCharPosition(tagChar) {
          const cursorPosition = this.getTextareaRef().selectionStart
  
          let position = cursorPosition
          // while (position > 0 && this.currentMessage.charAt(position - 1) !== tagChar) {
          //   position--
          // }
  
          const endPosition = this.getTextareaRef().selectionEnd
  
          return { position, endPosition }
        },
        retryMessage(messageId) {
          this.sendingMessage = true

          const message = this.currentMessages.find(m => m.id == messageId)
          this.postMessage(message.body, messageId, true)
        },
        sendMessageDesktop(event) {
          if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            event.stopPropagation();
  
            if (this.sendingMessage)
              return

            const message = this.currentMessage
            const date = new Date()
  
            const generatedId = -(parseInt(Math.random() * 10000000))
            const currentMinutes = ("0" + date.getMinutes()).slice(-2)
  
            this.pushMessage({
              body: this.currentMessage,
              id: generatedId,
              chat_uuid: this.activeChat.chat_uuid,
              author_id: this.current_user.id,
              author_nickname: this.current_user.nickname,
              created_at_date: `${("0" + date.getDate()).slice(-2)}.${("0" + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`,
              created_at_time: `${date.getHours()}:${currentMinutes}`,
              viewed: this.participantsOnlineIds.length > 1,
              error: false
            })
            this.currentMessage = ""
  
            this.resetTextareaSize()
            this.getTextareaRef().focus()

            this.postMessage(message, generatedId)
          }
        },
        postMessage(message, generatedId, resend = false) {
          this.sendingMessage = true

          axios
              .post(
                "/api/v1/chat_messages",
                { chat_message: { body: message }, chat_uuid: this.activeChat.chat_uuid }
              )
              .then(response => {
                this.sendingMessage = false

                if (resend) {
                  this.currentMessages.find(m => m.id == generatedId).error = false
                }
              })
              .catch(error => {
                this.errors = true
                this.sendingMessage = false
                this.currentMessages.find(m => m.id == generatedId).error = true
              })
        },
        sendMessageMobile(event) {
          event.preventDefault();
          event.stopPropagation();
  
          if (this.sendingMessage)
            return
  
          if (this.currentMessage.length == 0) {
            return
          }
  
          const message = this.currentMessage;
  
          this.sendingMessage = true
  
          $("#messages-input").trigger("click")
  
          const date = new Date()
  
          const generatedId = -(parseInt(Math.random() * 10000000))
          const currentMinutes = ("0" + date.getMinutes()).slice(-2)
  
          this.pushMessage({
            body: this.currentMessage,
            id: generatedId,
            chat_uuid: this.activeChat.chat_uuid,
            author_id: this.current_user.id,
            author_nickname: this.current_user.nickname,
            created_at_date: `${("0" + date.getDate()).slice(-2)}.${("0" + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`,
            created_at_time: `${date.getHours()}:${currentMinutes}`,
            viewed: this.participantsOnlineIds.length > 1,
            error: false
          })
          this.currentMessage = ""
          this.resetTextareaSize()
          this.getTextareaRef().focus()
  
          this.postMessage(message, generatedId)
        },
        loadPreviousMessages() {
          const latestMessage = this.currentMessages[0]
  
          axios
            .get(
              "/api/v1/chat_messages",
              { params: { last_message_id: latestMessage.id, chat_uuid: this.activeChat.chat_uuid } }
            )
            .then(response => {
              const { messages } = response.data
  
              if (messages.length == 0) {
                this.showLoadPreviousMessagesButton = false
                return
              }
  
              this.currentMessages = [...messages, ...this.currentMessages]
  
              // messages.forEach(m => this.currentMessages.unshift(m))
            })
            .catch(error => {
              this.errors = true
            })
        },
        showFullMessage(messages, currentMessageIndex) {
          if (currentMessageIndex == 0) {
            return true
          }
  
          const currentMessage = messages[currentMessageIndex]
          const prevMessage = messages[currentMessageIndex - 1]
  
          if (currentMessage.author_id != prevMessage.author_id) {
            return true
          }
  
          if (currentMessage.created_at_time != prevMessage.created_at_time) {
            return true
          }
  
          return false
        },
        isUserOnline(participant) {
          return _.includes(this.participantsOnlineIds, participant.id)
        },
        insert(emoji) {
          // tried to insert in cursor place
          // const { position, endPosition } = this.getCharPosition(':')
  
          // console.log('position', position);
          // console.log('end position', endPosition)
  
          // console.log('part 1', this.currentMessage.slice(0, position - 1))
          // console.log('part 2', this.currentMessage.slice(position, this.currentMessage.length - 1))
          // const finalPosition = position > 0 ? position - 1 : 1
  
          // this.currentMessage =
          //   this.currentMessage.substr(0, finalPosition) +
          //   emoji +
          //   this.currentMessage.substr(endPosition, this.currentMessage.length - 1)
  
          this.currentMessage += emoji;
  
          this.getTextareaRef().focus()
        },
        sportEventLink() {
          if (this.chat.sport_event) {
            return `/sport_events/${this.chat.sport_event.id}`
          }

          return null
        },
        changeBodyType() {
          if (this.bodyType == "messages") {
            this.bodyType = "users"
          } else {
            this.bodyType = "messages"
          }
        },
        renderedChatTitle() {
          if (this.bodyType == "messages") {
            return I18n.t("chat.members")
          } else {
            return I18n.t("chat.messages")
          }
        },
        imgPath(user) {
          return showAvatar(user, 50)
        },
        userLink(user) {
          return `/users/${user.id}`
        },
        messageAuthor(message) {
          let author = this.groupedParticipants[message.author_id]
          author = author ? author[0] : author
          return author
        }
      }
    }
  </script>
  
  <style scoped>
  
    .chat-container {
      max-width: 760px;
      height: 100%;
      padding-left: 0px;
      padding-right: 0px;
      background: #fff;
    }
  
    .chat {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  
    .chat__topic {
      max-height: 54px;
      overflow: hidden;
      position: relative;
      word-break: break-word;
      word-wrap: break-word;
      padding-bottom: 1px;
      font-size: 13px;
      font-weight: 700;
      line-height: 1.231;
      color: #004d99!important;
      display: flex;
      justify-content: space-between;
      flex-basis: 10%;
      box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);
      align-items: center;

      @media (max-width: 760px) {
        max-height: 58px;
        box-shadow: none;
        border-bottom: 1px solid #d3d3d3;
      }
    }
  
    .chat__topic a {
      text-decoration: none;
    }
  
    .chat__topic svg {
    }
  
    .arrow-left-link-wrap {
      margin-left: 15px;
    }

    .chat__title {
      margin-left: 16px;
      width: 100%;
      position: relative;
    }
  
    .users-list-link-wrap {
      align-self: center;
      padding: 10px;
      user-select: none;
      cursor: pointer;
      border-radius: 5px;
      text-align: center;
      font-size: 15px;
      color: black;
  
      @media (max-width: 640px) {
        font-size: 14px;
      }
    }
  
    .users-list-link-wrap:hover {
      box-shadow: 0px 2px 5px #b9b6b6;
  
      @media (min-width: 320px) and (max-width: 767px) {
        box-shadow: none;
      }
    }
  
    .empty-messages {
      margin-top: auto;
      margin-bottom: auto;
      text-align: center;
      font-size: 16px;
  
      @media (min-width: 320px) and (max-width: 767px) {
        font-size: 16px;
      }
    }
  
    .chat__messages {
      padding-top: 10px;
      padding-bottom: 22px;
      overflow-y: auto;
      height: 100%;
      background-size: 100%;
      padding-bottom: 30px;
      padding-left: 20px;
      padding-right: 20px;
  
      @media (min-width: 320px) and (max-width: 767px) {
        padding-left: 15px;
        padding-right: 10px;
      }
    }
  
    @-moz-document url-prefix() {
      .chat__messages {
        padding-bottom: 0px;
        margin-bottom: 22px;
      }
    }
  
    .chat__input-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
  
      @media (min-width: 320px) and (max-width: 767px) {
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  
    .chat__input {
      /* Remove First */
      /*-webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
  
      outline: 0;
      min-height: 36px;
      width: 100%;
      resize: none;
      max-height: 200px;
      border-radius: 6px;
      line-height: 17px;
      padding: 9px 40px 10px 13px;
      margin-bottom: 0;
      word-wrap: break-word;
      box-sizing: border-box;
      border: solid 1px #d3d9de;
      font-size: 14px;
  
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90%;
        font-size: 16px;
        border-radius: 20px;
      }*/
  
      min-height: 20px;
      padding-left: 12px;
      height: 20px;
  
      max-height: 300px;
      overflow-y: auto;
      height: 20px;
      width: 100%;
      line-height: 20px;
      outline: 0;
      resize: none;
      border-radius: 10px;
      padding: 7px 16px;
      box-sizing: content-box;
      font-size: 14px;
      max-width: 640px;
      border: 1px solid #e1e4e8;
  
      @media (min-width: 320px) and (max-width: 767px) {
        border-radius: 20px;
        padding: 6px 16px;
        width: 70%;
        font-size: 100%;
      }
    }
  
    .chat__messages_date {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0px 24px;
      padding-top: 12px;
      padding-bottom: 10px;
      font-size: 11px;
      line-height: 1.273;
      color: #333;
      text-align: center;
      box-sizing: border-box;
    }
  
    .chat__load-previous-messages {
      text-align: center;
      padding-bottom: 10px;
    }
  
    .chat__load-previous-messages a {
      font-size: 14px;
      text-decoration: none;
      color: #565656;
    }
  
    .chat__users__block {
      height: 100%;
      padding-left: 15px;
      padding-top: 10px;
      overflow-y: auto;
      flex-basis: 87%;
      margin-top: 12px;
    }
  
    .chat__users__wrap {
      display: flex;
      flex-wrap: wrap;
    }
  
    .chat__user {
      margin-right: 15px;
      padding-bottom: 20px;
    }
  
    .chat__user-online {
      background: #0fd815;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      bottom: -2px;
      right: 4px;
      position: absolute;
      border: 1px solid #fff;
    }
  
    .chat__user-name {
      text-align: center;
      font-size: 12px;
      margin-top: 6px;
    }
  
    .chat__user-name a {
      font-size: 12px;
      text-decoration: none;
    }
  
    .chat__user-avatar {
      position: relative;
      text-align: center;
    }
  
    .chat__user-avatar img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  
    label.chat__send-label {
      width: 36px;
      min-width: 0;
      height: 32px;
      padding: 0;
      border: 0;
      margin-bottom: 0px;
  /*    background-image: url(../../images/Iconset.png);*/
      background-repeat: no-repeat;
      background-position: 2px -860px;
      background-size: 42px 1171px;
    }
  
    #vac-icon-send {
      fill: #1976d2;
    }
  
    #vac-icon-send-disabled {
      fill: #9ca6af;
    }
  
    .chat__send-button-opacity {
      opacity: 1 !important;
    }
  
    .send-icon-svg {
      margin-left: 12px;
      margin-right: -14px;
      margin-bottom: -10px;
    }
  
    .typing-wrap {
      display: flex;
      font-size: 12px;
      color: grey;
      padding-left: 20px;
      margin-bottom: 10px;
  
      @media only screen and (max-width: 600px) {
        padding-left: 15px;
      }
    }
  
    .typing-item {
      margin-right: 5px;
    }

    .sport-event-icon {
      height: 20px;
      width: 20px;
      position: absolute;
      top: 10px;
      left: 8px;

      @media only screen and (max-width: 600px) {
        height: 20px;
        width: 20px;
        top: 8px;
        left: 8px;
      }
    }
  
    .emoji-invoker {
  /*    position: absolute;
      top: 0.4rem;
      right: 3%;*/
      margin-left: 8px;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.2s;
  
      @media (min-width: 320px) and (max-width: 767px) {
        right: 12%;
      }
    }
  
    .emoji-invoker:hover {
      transform: scale(1.1);
    }
  
    .emoji-invoker > svg {
      fill: #000;
    }
  
    .emoji-picker {
      position: absolute;
      bottom: 50px;
      right: 1px;
      z-index: 10;
      font-family: Montserrat;
      border: 1px solid #ccc;
      width: 16rem;
      height: 20rem;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 1rem;
      box-sizing: border-box;
      border-radius: 0.5rem;
      background: #fff;
      box-shadow: 1px 1px 8px #c7dbe6;
  
      @media only screen and (max-width: 600px) {
        width: 18rem;
      }
    }
  
    .emoji-picker h5+div {
      display: flex;
      flex-wrap: wrap;
    }
  
    .emoji-picker__search {
      display: flex;
    }
  
    .emoji-picker__search > input {
      flex: 1;
      border-radius: 10rem;
      border: 1px solid #ccc;
      padding: 0.5rem 1rem;
      outline: none;
    }

    .chat-title-members {
      font-size: 14px; 
      font-weight: 600; 
      position: relative; 
      left: calc(40% - 12px);

      @media only screen and (min-width: 600px) {
        left: 5%;
      }
    }
  
    .emoji-picker h5 {
      margin: 8px 0;
      color: #b1b1b1;
      text-transform: uppercase;
      font-size: 0.8rem;
      cursor: default;
      font-family: "Graphik", "Helvetica Neue", helvetica, "Apple Color Emoji", arial, sans-serif;
      font-size: 15px;
    }
  
    .emoji-picker span {
      font-size: 22px;
      margin-right: 8px;
      display: block;
      margin-bottom: 6px;
      cursor: pointer;
  
      @media only screen and (max-width: 600px) {
        font-size: 32px;
      }
    }
  
    .emoji-picker .emojis {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    .emoji-picker .emojis:after {
      content: "";
      flex: auto;
    }
  
    .emoji-picker .emojis span {
      padding: 0.2rem;
      cursor: pointer;
      border-radius: 5px;
    }
  
    .emoji-picker .emojis span:hover {
      background: #ececec;
      cursor: pointer;
    }
  
    .mobile-logo {
      background: url("../../images/logo.svg");
      background-repeat: no-repeat;
      width: 50px;
      height: 50px;
      position: relative;
      top: -12px;
      left: 50%;
    }

    .user-sport-item {
      margin-right: 12px;
      position: relative;
      background-color: #7d79b9;
      height: 40px;
      width: 40px;
      border-radius: 10px;

      @media only screen and (max-width: 600px) {
        height: 35px;
        width: 35px;
        margin-right: 18px;
      }
    }

    .badminton {
        background-image: url("../../images/mini-image-badminton.svg");
        background-repeat: no-repeat;
        background-size: 20px 20px;
    }

    .squash {
        background-image: url("../../images/mini-image-squash.svg");
        background-repeat: no-repeat;
        background-size: 20px 20px;
    }

    .football {
        background: url("../../images/mini-image-football.svg");
        background-repeat: no-repeat;
        background-size: 20px 20px;
    }

    .basketball {
        background: url("../../images/mini-image-basketball.svg");
        background-repeat: no-repeat;
        background-size: 20px 20px;
    }

    .tennis {
        background: url("../../images/mini-image-tennis.svg");
        background-repeat: no-repeat;
        background-size: 20px 20px;
    }

    .tabletennis {
        background: url("../../images/mini-image-tabletennis.svg");
        background-repeat: no-repeat;
        background-size: 20px 20px;
    }

    .volleyball {
        background: url("../../images/mini-image-volleyball.svg");
        background-repeat: no-repeat;
        background-size: 20px 20px;
    }
  
  </style>
  